import axios, { AxiosInstance } from 'axios';

export default class Api {
  private token;
  private interceptor;
  constructor(token: string) {
    this.token = token;
    this.init();
  }

  private init = () => {
    this.interceptor = config => {
      if (this.token) {
        const headers = { ...config.headers };
        return { ...config, headers };
      }
      return config;
    };
  };
  // private interceptor

  getClient = (): AxiosInstance => {
    // private api for analyze client
    const tensaiFormsClient = axios.create({
      baseURL: process.env.REACT_APP_TENSAI_FORMS_URL,
    });
    tensaiFormsClient.interceptors.request.use(this.interceptor);
    return tensaiFormsClient;
  };
}
