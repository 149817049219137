import { Alternativa } from 'core/pesquisa/Pesquisa';
import { AlternativaSelecionada } from 'core/pesquisa/resposta/pergunta/aternativa/RespostaAlternativa';
import React, {
  MutableRefObject,
  SyntheticEvent,
  useEffect, useRef,
  useState
} from 'react';

interface Props {
  alternativa: Alternativa;
  onChange: (checked: boolean) => void;
  onChangeTexto: (value: string, alternativa: AlternativaSelecionada) => void;
  checked: boolean;
  // radio?: boolean;
  placeholder?: string;
  abertaNumero: boolean;
}

const AlternativaAbertaOrdemSelecao: React.FC<Props> = ({
  alternativa,
  onChange,
  onChangeTexto,
  checked,
  // radio = false,
  abertaNumero = false,
  placeholder = 'Outro',
}: Props) => {
  const [valorAlternativa, setValorAlternativa] = useState('');
  useEffect(() => {
    if (!checked) {
      setValorAlternativa('');
    }
  }, [checked]);
  const handleChange = ev => {
    if (onChange) onChange({ ...ev, checked: !checked, value: alternativa.id });
  };
  const handleChangeText = (
    element: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (abertaNumero) {
      const re = /^[0-9\b]+$/;

      // if value is not blank, then test the regex

      if (element.target.value === '' || re.test(element.target.value)) {
        setValorAlternativa(element.target.value);
        onChangeTexto(element.target.value, {
          id_alternativa: alternativa.id,
          ordem_selecao: alternativa.ordem,
          tipo_alternativa: alternativa.tipo_alternativa,
          comportamento: alternativa.comportamento,
        });
      }
    } else {
      setValorAlternativa(element.target.value);
      if (onChangeTexto) {
        onChangeTexto(element.target.value, {
          id_alternativa: alternativa.id,
          ordem_selecao: alternativa.ordem,
          tipo_alternativa: alternativa.tipo_alternativa,
          comportamento: alternativa.comportamento,
        });
      }
    }
  };

  // Pega referência do texto para aumentar as rows (simular quebra de linha) do textarea de acordo com o texto digitado
  const textRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  useEffect(() => {
    textRef.current.focus(); // da foco no textarea
  }, [checked]);
  const onChangeHandler = (e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    if (textRef.current !== null) {
      textRef.current.style.height = '30px';
      textRef.current.style.height = `${target.scrollHeight}px`;
    }
  };

  return (
    <div className="font-roboto inline-flex">
      <div
        className="border border-white rounded-full w-28p h-28p bg-transparent"
        role="button"
        onClick={e => {
          handleChange(e);
        }}
        aria-hidden="true"
      >
        {checked && (
          <p className="text-xs pl-1.75 pt-5p pl-10p text-green-500">
            {alternativa.ordem}
          </p>
        )}
      </div>

      <textarea
        wrap="hard"
        style={{
          width: '100%',
          minHeight: '30px',
          resize: 'none',
          overflow: 'hidden',
        }}
        rows={1}
        maxLength={255}
        ref={textRef}
        onKeyUp={onChangeHandler}
        className="form-input ml-4 border-none text-white outline-none mt-2"
        id={`${alternativa.id}`}
        placeholder={placeholder}
        disabled={!checked}
        onChange={handleChangeText}
        value={valorAlternativa}
      />
    </div>
  );
};

export { AlternativaAbertaOrdemSelecao };
