import { TipoPergunta } from 'core/pesquisa/Pesquisa';
import { RespostaPergunta } from './RespostaPergunta';

const tipoPerguntaComAlternativas = [
  TipoPergunta.GRADE_MULTIPLA,
  TipoPergunta.GRADE_UNICA,
  TipoPergunta.UNICA,
  TipoPergunta.ESCALA_NUMERICA,
  TipoPergunta.MULTIPLA,
  TipoPergunta.MULTIPLA_ORDEM_CITACAO,
  TipoPergunta.ABERTA,
] as string[];

const tipoPerguntaComBlob = [TipoPergunta.FOTO, TipoPergunta.VIDEO] as string[];

function respostaFactory(argName: string | undefined, ...values) {
  const [firstValue, idPergunta, tipoPergunta, pulada] = values;

  const result = {
    id_pergunta: idPergunta,
    tipo_pergunta: tipoPergunta,
    pulada,
  };

  if (argName !== undefined && firstValue !== undefined) {
    result[argName] = firstValue;
  }

  return result;
}

/**
 *
 * @param idPergunta id da pergunta
 * @param tipoPergunta tipo da pergunta
 * @param pulada indica se a pergunta foi pulada
 * @param value resposta da pergunta, pode ser cada um dos tipos de respostas para cada pergunta.
 */
export function getRespostaPerguntaByTipoPergunta(
  idPergunta: number,
  tipoPergunta: string,
  pulada: boolean,
  value: unknown,
): RespostaPergunta {
  // Representa o atributo de envio da resposta de acordo com o tipo da pergunta
  let key: string | undefined;
  // Representa o valor default do atributo key, por exemplo, uma pergunta do tipo UNICA sempre terá sua resposta
  // armazenada em um vetor representado por uma chave "alternativas_selecionadas: [{...}]"
  // no caso do usuário não responder uma pergunta, seu valor default seria apenas um array vazio [], que é esperado
  // pelo backend no payload final de envio.
  let defaultValue: unknown;

  // Caso seja alguma pergunta que possua suas respostas armazenadas no atributo "alternativas_selecionadas"
  if (tipoPerguntaComAlternativas.includes(tipoPergunta)) {
    key = 'alternativas_selecionadas';
    defaultValue = [];
  }

  // Caso seja alguma pergunta que possua suas respostas armazenadas no atributo "foto"
  // TODO: Avaliar se está correto que o atributo foto realmente armazene os dados de um video enviado
  // porque isso não parece fazer sentido.
  if (tipoPerguntaComBlob.includes(tipoPergunta)) {
    key = 'foto';
    defaultValue = null;
  }

  // Caso seja alguma pergunta que possua suas respostas armazenadas no atributo "data"
  if (tipoPergunta === TipoPergunta.DATA) {
    key = 'data';
    defaultValue = null;
  }

  // Caso seja alguma pergunta que possua suas respostas armazenadas no atributo "horario"
  if (tipoPergunta === TipoPergunta.HORARIO) {
    key = 'horario';
    defaultValue = null;
  }

  const defaultArgs = [
    // Caso value seja undefined, serão passados por parametro o seu respectivo valor default, de acordo
    // com a distribuição dinâmica feita acima
    value !== undefined ? value : defaultValue,
    idPergunta,
    tipoPergunta,
    pulada,
  ];

  // Objeto de resposta final que será consumido no payload
  return respostaFactory(key, ...defaultArgs);
}
