import { Pergunta } from 'core/pesquisa/Pesquisa';
import { useInputError } from 'hooks/useInputError';
import CoreContext from 'providers/core';
import {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { InputErrors } from '..';
import { genericRespostaAtual, PerguntaRef } from '../interfaces';
import {
  ClearTextArea,
  Container,
  InputErrorMsg,
  SpanError,
  Text,
  TextArea,
  TextAreaContainer,
} from './styles';

enum RespostaError {
  OBRIGATORIA = 'obrigatória',
  INVALIDO = 'inválida',
}
interface Props {
  pergunta: Pergunta;
  // idSecao: number;
  // className: string;
  // placeholder: string;
  respostaAtual: (id: number) => genericRespostaAtual;
}

const placeholderText = 'Escreve sua resposta aqui...';
const PerguntaAbertaReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const { pergunta, respostaAtual } = props;
  const { errosValidationQuestions } = useContext(CoreContext);
  const [error, setErrors] = useState<string>('');
  const [inputError, validate] = useInputError(); // hook para controle dos inputErrors

  const placeholder =
    (pergunta.alternativas &&
      pergunta.alternativas[0] &&
      pergunta.alternativas[0].enunciado) ||
    placeholderText;

  const [value, setValue] = useState<string>('');

  // Pega referência do texto para aumentar as rows (simular quebra de linha) do textarea de acordo com o texto digitado
  const textRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const containerRef = useRef() as MutableRefObject<HTMLElement>;

  useImperativeHandle(ref, () => {
    return {
      alternativas: pergunta.alternativas
        ? [
            // Verificação adicionada, pois o array com a alternativa default
            // só deve ser enviado se o usuário responder a alternativa
            // anteriormente essa alternativa default estava sendo enviada
            // mesmo se a pergunta fosse opcional e o usuário não respondesse
            // o que gerava conflito no back-end.
            ...(value
              ? [
                  {
                    id_alternativa: pergunta.alternativas[0].id,
                    ordem_selecao: 1,
                    tipo_alternativa: pergunta.alternativas[0].tipo_alternativa,
                    texto: value || null,
                    comportamento: pergunta.alternativas[0].comportamento,
                  },
                ]
              : []),
          ]
        : [],
      data: null,
      foto: null,
      horario: null,
      texto: value || null,
      destinoPulo: null,
      alternativas_selecionadas: null,
      vazia: value === '',
    };
  });

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);

    if (
      respostaLocalAtual &&
      typeof respostaLocalAtual.estadoAtual === 'string'
    ) {
      setValue(respostaLocalAtual.estadoAtual);
    }
  }, [pergunta.id, respostaAtual]);

  const onChangeHandler = e => {
    const answer = e.target.value;
    const targetTextArea = e.target as HTMLTextAreaElement;
    if (textRef.current !== null) {
      textRef.current.style.height = '30px';
      textRef.current.style.height = `${targetTextArea.scrollHeight}px`;
      sessionStorage.setItem(
        `perguntaAberta-${pergunta.id}`,
        textRef.current.style.height,
      );
    }
    if (!answer.trim().length) {
      setTimeout(() => {
        return setValue('');
      }, 100);
    }
    setValue(answer);
    validate(answer, InputErrors.TEXTO_MAX_LENGTH);
    setErrors('');
  };

  const keyValidate = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const clearTextArea = () => {
    setValue('');
    validate('', InputErrors.TEXTO_MAX_LENGTH);
    textRef.current.style.height = '48px';
  };

  useEffect(() => {
    const required = errosValidationQuestions.indexOf('OBRIGATORIA');
    if (required >= 0) {
      setErrors(errosValidationQuestions[required]);
    }
  }, [errosValidationQuestions]);

  return (
    <Container ref={containerRef}>
      <Text>Insira sua resposta aqui</Text>
      <TextAreaContainer className="pergunta-aberta">
        <TextArea
          error={!!error.length || inputError}
          ref={textRef}
          maxLength={InputErrors.TEXTO_MAX_LENGTH + 1}
          style={{
            height: `${
              sessionStorage.getItem(`perguntaAberta-${pergunta.id}`) !== ''
                ? sessionStorage.getItem(`perguntaAberta-${pergunta.id}`)
                : '48px'
            }`,
          }}
          className="form-input border-none text-white outline-none text-contrasteTensai"
          onChange={e => onChangeHandler(e)}
          value={value}
          placeholder={placeholder}
          onKeyDown={e => keyValidate(e)}
        />
        {error.length > 0 && (
          <SpanError>
            Resposta {errosValidationQuestions.map(err => RespostaError[err])}
          </SpanError>
        )}
        {inputError && (
          <InputErrorMsg>
            Limite de {InputErrors.TEXTO_MAX_LENGTH} caracteres
          </InputErrorMsg>
        )}
        {value.length > 0 && (
          <ClearTextArea onClick={clearTextArea}>{'\u2715'}</ClearTextArea>
        )}
      </TextAreaContainer>
    </Container>
  );
};
const PerguntaAberta = forwardRef(PerguntaAbertaReferenciable);
export { PerguntaAberta };
