import { Container, Icon } from './styles';

const LoadingSimple = () => {
  return (
    <Container>
      <Icon />
    </Container>
  );
};

export { LoadingSimple };
