import { Pergunta } from '../Pesquisa';
import { RespostaPergunta } from './pergunta/RespostaPergunta';

export class RespostaPerguntaAggregate {
  perguntaComSecao: PerguntaComSecao;
  respostaPergunta?: RespostaPergunta;
  idPerguntaDestinoPulo?: number;

  constructor(
    pergunta: PerguntaComSecao,
    respostaPergunta?: RespostaPergunta,
    idPerguntaDestinoPulo?: number,
  ) {
    this.perguntaComSecao = pergunta;
    this.respostaPergunta = respostaPergunta;
    this.idPerguntaDestinoPulo = idPerguntaDestinoPulo;
  }
}

export class PerguntaComSecao {
  pergunta: Pergunta;
  idSecao: number;

  constructor(pergunta: Pergunta, idSecao: number) {
    this.pergunta = pergunta;
    this.idSecao = idSecao;
  }
}
