import { RespostaPesquisa, RespostaSecao } from './RespostaPesquisa';
import { RespostaPerguntaAggregate } from './RespostaPesquisaAggregate';

class RespostaPesquisaFactory {
  getInstance = (
    versaoPesquisa: string,
    tempoDuracao: string,
    respostas: RespostaPerguntaAggregate[],
  ): RespostaPesquisa => {
    return new RespostaPesquisa(
      versaoPesquisa,
      tempoDuracao,
      this.gerarSecoes(respostas),
    );
  };

  // Função criada para remover comportamento das alternativas que estão relacionados ao passo de lógica
  private removeComportamentoAlternativa = (
    respostas: RespostaPerguntaAggregate[],
  ) => {
    let data;

    if (respostas) {
      data = respostas;
    }

    // Criado validação para conseguir acessar alternativas_selecionadas sem erro na tipagem
    data.forEach(resp => {
      if (Array.isArray(resp.respostaPergunta.alternativas_selecionadas)) {
        resp.respostaPergunta.alternativas_selecionadas.forEach(
          // eslint-disable-next-line no-param-reassign
          (alternative): boolean => delete alternative.comportamento,
        );
      }
    });
  };

  private gerarSecoes = (
    respostas: RespostaPerguntaAggregate[],
  ): RespostaSecao[] => {
    this.removeComportamentoAlternativa(respostas);
    const respostaSecaoMap: { [key: number]: RespostaSecao } = {};
    respostas.forEach(agg => {
      const { idSecao } = agg.perguntaComSecao;

      let respostaSecao;
      if (!respostaSecaoMap[idSecao]) {
        respostaSecao = new RespostaSecao(idSecao, []);
        respostaSecaoMap[idSecao] = respostaSecao;
      } else {
        respostaSecao = respostaSecaoMap[idSecao];
      }

      if (!agg.respostaPergunta) {
        throw new Error(
          `A pergunta ${agg.perguntaComSecao.pergunta} não possui RespostaPergunta, mesmo que o usuario nao fornceca uma resposta, um objeto RespostaPergunta deve ser fornecido`,
        );
      }

      respostaSecao.perguntas.push(agg.respostaPergunta);
    });
    return Object.values(respostaSecaoMap);
  };
}

export default RespostaPesquisaFactory;
