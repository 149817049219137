import React from 'react';
import { ReactComponent as VideoImg } from '../../assets/images/video.svg';

const Video: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <VideoImg width="22px" height="15px" className={className} />;
};

export { Video };
