import styled from 'styled-components';

export const CalendarWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CalendarLabel = styled.span`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
 `;

export const CalendarInput = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: 5px;
  width: 244px;
  height: 40px;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.gray500};
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }

  .calendarIcon {
    fill: ${({ theme }) => theme.colors.gray400};
    cursor: pointer;
  }

  @media only screen and (max-width: 425px) {
    width: 100%;
  }

  .react-datepicker {
    top: 8px;
    left: -18px;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    padding-top: 16px;
    align-items: start;
    display: flex;
    flex-direction: column;
    padding-inline: 16px;
    gap: 18px;
  }

  .react-datepicker__triangle {
    visibility: hidden;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: transparent;
  }

  .react-datepicker__day-names {
    align-self: center;
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.gray500};
    font-family: "Open Sans";
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
    padding: 2px;
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.gray500};
    background-color: transparent;
    border: solid 1px transparent;
    font-family: "Open Sans";
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    padding: 4px;
  }

  .react-datepicker__day:hover {
    background-color: transparent;
  }

  .react-datepicker__day--selected {
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent !important;
    font-weight: bold;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 3px 2px;
    border-radius: 18px !important;
  }

  .react-datepicker__day--today {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.gray300} !important;
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.gray300} !important;
  }

  .react-datepicker__month-container {

    border: none;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);
    border-radius: 5px;

    border: 1px solid ${({ theme }) => theme.colors.gray200};
    border-bottom: none;
  }

  @media only screen and (min-width: 375px) {
    .react-datepicker__month-container {
      width: 290px;
    }
  }

  .react-datepicker__week {
    padding:  0 16px;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__navigation--next {
    display: none;
  }

  .react-datepicker__navigation--previous {
    display: none;
  }

  .react-datepicker__header__dropdown {
    display: flex;
    gap: 4px;
  }

  .react-datepicker__navigation--years-previous,
  .react-datepicker__navigation--years-upcoming {
    display: none;
  }

  .react-datepicker__month-read-view,
  .react-datepicker__year-read-view {
    visibility: visible !important;
    color: ${({ theme }) => theme.colors.gray400};
    font-size: 14px;
    padding: 8px 8px 8px 0px;
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    border-radius: 4px;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    border-top-color: ${({ theme }) => theme.colors.gray400};
    border-width: 0.25rem;
  }

  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    text-transform: capitalize;
    padding-right: 8px;
  }

  .react-datepicker__month-dropdown {
    position: absolute;
    width: 88px;
    height: 230px;
    top: 38px;
    left: 2px;
    z-index: 1;
    text-align: start;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray300};
    border: none;
    border-radius: 3px;
    padding: 4px 8px;
    overflow-y: scroll;

    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-transform: capitalize;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__year-dropdown {
    position: absolute;
    width: 67px;
    height: 230px;
    top: 38px;
    left: 18px;
    z-index: 1;
    text-align: start;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray300};
    border: none;
    border-radius: 3px;
    padding: 4px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__year-dropdown-container {
    position: relative;
  }

  .react-datepicker__month-dropdown-container {
    position: relative;
  }

  .react-datepicker__year-dropdown::-webkit-scrollbar {
    width: 10px;
  }

  .react-datepicker__year-dropdown::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 4px solid ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.gray300};
    height: 30px;
  }

  .react-datepicker__year-dropdown::-webkit-scrollbar-track {
    margin: 4px 10px 4px;
  }

  .react-datepicker__month-option,
  .react-datepicker__year-option {
    color: ${({ theme }) => theme.colors.gray300};
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px
  }

  .react-datepicker__month-option--selected,
  .react-datepicker__year-option--selected {
    display: none;
  }

  .react-datepicker__month-option--selected_month,
  .react-datepicker__year-option--selected_year {
    color: ${({ theme }) => theme.colors.gray500};
  }

  .react-datepicker__month-option:hover,
  .react-datepicker__year-option:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.gray500};
  }

  .react-datepicker {
    border: none;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .datepicker__footer {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    border-top: none;
    border-radius: 5px;
    padding: 0 16px 16px 16px;
    position: absolute;
    height: 42px;
    bottom: -40px;
    left: 0;
    right: 0;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .datepicker__btn__clean {
    color: ${({ theme }) => theme.colors.gray400};
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    border: none;
    padding: 2px 8px;
    align-self: center;
  }
`;
