import CoreContext from 'providers/core';
import { createContext, useContext, useEffect, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { mapTheme } from 'styles/theme/colors';

interface IThemeContextData {
  theme: string;
}

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

const ThemeProviderApp = ({ children }) => {
  const { tema } = useContext(CoreContext);

  const defaultTheme: DefaultTheme = mapTheme('default');
  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);

  const getFormsTheme = () => {
    const backendTheme = tema;
    const newTheme = mapTheme(backendTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    getFormsTheme();
  }, [tema]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ theme: tema }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

const useTheme = (): IThemeContextData => {
  const context = useContext(ThemeContext);

  return context;
};

export { ThemeProviderApp, useTheme };
