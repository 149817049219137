import { Check } from 'components/Icons/Check';
import { ImgUpload } from 'components/Icons/ImgUpload';
import { Reload } from 'components/Icons/Reload';
import { Video } from 'components/Icons/Video';
import {
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ErrorInput, GrayInputTitle, InputTitle, OutlineInput, ReloadBtn, UploadInput } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  preview: File | null;
  setPreview: Dispatch<SetStateAction<File | null>>;
  video?: boolean;
}

const InputFile = ({
  name,
  preview,
  setPreview,
  video = false,
  ...rest
}: InputProps) => {
  const [progress, setProgress] = useState(preview ? 'uploaded' : 'uploadFile');
  useEffect(() => {
    if (progress === 'uploading' && preview !== null) {
      setProgress('uploaded');
    }
    if (preview === null) setProgress('uploadFile');
  }, [preview, progress]);

  useEffect(() => {
    if (preview instanceof File) {
      setProgress('uploaded');
    }
  }, [preview]);

  const inputContent = () => {
    switch (progress) {
      case 'uploadFile':
        return (
          <UploadInput>
            <input
              type="file"
              className="absolute top-0 left-0 w-full bg-gray-500 h-14 opacity-0 cursor-pointer"
              id={name}
              name={name}
              onChange={event => {
                setProgress('uploading');
                if (!event.target.files) setProgress('error');
                if (event.target.files) setPreview(event.target.files[0]);
                if (rest.onChange) rest.onChange(event);
              }}
              {...rest}
            />

            {video 
              ? <Video className="cursor-pointer" />
              : <ImgUpload className="cursor-pointer" /> }

            <InputTitle>
              {video ? 'Enviar vídeo' : 'Enviar imagem'}
            </InputTitle>
          </UploadInput>
        );
      case 'uploading':
        return (
          <InputTitle>
            Carregando...
          </InputTitle>
        );
      case 'uploaded':
        return (
          <div className="flex gap-3 items-center">
            <OutlineInput>
              <Check className="outline-input-check" />
              <GrayInputTitle>Upload feito com sucesso</GrayInputTitle>
            </OutlineInput>
            <ReloadBtn
              onClick={() => {
                setPreview(null);
              }}
            >
              <Reload className="flex" />
            </ReloadBtn>
          </div>
        );
      default:
        return (
          <div className="flex gap-3 items-center">
            <ErrorInput>
              <GrayInputTitle>
                Erro no upload, tente novamente
              </GrayInputTitle>
            </ErrorInput>
            <ReloadBtn
              onClick={() => {
                setPreview(null);
              }}
            >
              <Reload className="flex" />
            </ReloadBtn>
          </div>
        );
    }
  };
  return <div className="flex flex-col gap-5">{inputContent()}</div>;
};

export default InputFile;
