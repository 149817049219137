import { OutlineButton } from 'components/OutlineButton';
import { Pergunta } from 'core/pesquisa/Pesquisa';
import pt from 'date-fns/locale/pt-BR';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as Calendar } from '../../../assets/images/new-calendar.svg';
import { PerguntaRef, genericRespostaAtual } from '../interfaces';
import { CalendarInput, CalendarLabel, CalendarWrapper } from './styles';

registerLocale('pt-BR', pt);
interface Props {
  pergunta: Pergunta;
  respostaAtual: (id: number) => genericRespostaAtual;
}
const dataPlaceHolder = 'dd/MM/yyyy';

const PerguntaDataReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const { pergunta, respostaAtual } = props;

  const calendarRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState<null | Date>(null);
  const [datePickerKey, setDatePickerKey] = useState(0);
  useImperativeHandle(ref, () => ({
    alternativas: null,
    data: date instanceof Date ? date.toLocaleDateString('pt-BR') : null,
    horario: null,
    foto: null,
    texto: null,
    alternativas_selecionadas: null,
    destinoPulo: null,
    vazia: date === null,
  }));

  const handleChange = (newDate: Date) => {
    setDate(newDate);
    setIsOpen(false);
  };

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);
    if (
      respostaLocalAtual &&
      typeof respostaLocalAtual.estadoAtual === 'string'
    ) {
      const [dia, mes, ano] = respostaLocalAtual.estadoAtual.split('/');
      const novaData = `${ano}/${mes}/${dia}`;

      // Verifica se a data parseada é valida para setar como estado inicial
      if (Date.parse(novaData)) {
        setDate(new Date(novaData));
      }
    }
  }, [pergunta.id, respostaAtual]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !(calendarRef.current as any).contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => { document.removeEventListener('mousedown', handleClickOutside); };
  }, []);

  /**
   * setDatePickerKey atualiza o estado para forçar a remontagem do componente de calendário
   * fazendo com que os seletores de mês e ano sejam atualizados
   */
  const handleCleanData = () => {
    setDate(null);
    setDatePickerKey(prevKey => prevKey + 1);
  };

  function handleRawDateInput(event) {
    if (event.target.value) {
      event.target.value = event.target.value.replace(/[^\d]+/g, '');
      if (event.target.value.length > 2) {
        event.target.value = event.target.value.replace(
          /^(\d{2})(\d{0,2})/,
          '$1/$2',
        );
      }
      if (event.target.value.length > 5) {
        event.target.value = event.target.value.replace(
          /^(\d{2})\/(\d{2})(\d{0,4})/,
          '$1/$2/$3',
        );
      }
      if (event.target.value.length > 10) {
        event.target.value = event.target.value.slice(0, 10);
      }
    }
  }

  const MAX_DATE = new Date(
    new Date().getFullYear() + 20,
    new Date().getMonth() + 1,
    12,
  );

  return (
    <CalendarWrapper>
      <CalendarLabel>Insira uma data</CalendarLabel>
      <CalendarInput ref={calendarRef}>
        <DatePicker
          key={datePickerKey}
          open={isOpen}
          onChange={handleChange}
          onChangeRaw={event => handleRawDateInput(event)}
          selected={date}
          locale="pt-BR"
          calendarClassName="text-white bg-dashboardBackground-main"
          className="leading-5 border-none bg-transparent outline-none flex"
          dateFormat={dataPlaceHolder}
          placeholderText="dd/mm/aaaa"
          showMonthDropdown
          showYearDropdown
          maxDate={MAX_DATE}
          scrollableYearDropdown
          yearDropdownItemNumber={120}
          formatWeekDay={w => w.toString().substr(0, 1).toUpperCase()}
        >
          <div className="datepicker__footer">
            <OutlineButton
              title="Limpar"
              onClick={() => {
                handleCleanData();
                setIsOpen(false);
              }}
              themeType="different"
              hasBorder={false}
              hasUnderline
            />
          </div>
        </DatePicker>
        <Calendar
          className="calendarIcon"
          onClick={() => {
            setIsOpen(true);
          }}
          
        />
      </CalendarInput>
    </CalendarWrapper>
  );
};
const PerguntaData = forwardRef(PerguntaDataReferenciable);
export { PerguntaData };
