import { DraftMessage } from 'components/DraftMessage';
import CoreContext from 'providers/core';
import { useContext } from 'react';
import { FinalizarPesquisa } from 'sections/FinalizarPesquisa';
import { GenericError } from './GenericError';
import { Container } from './style';

interface Props {
  statusCode: number;
}

const PaginaDeErro = ({ statusCode }: Props) => {
  const { logo } = useContext(CoreContext);
  
  switch (statusCode) {
    case 404:
      return (
        <GenericError
          statusCode={statusCode}
          title="Ops! Algo não aconteceu como esperado"
          message="Página não encontrada.</br>
          Verifique se a URL está correta e recarregue a página.</br>
          Caso o erro persista contate: suporte@tensai.com.br"
        />
      );
    case 422:
      return (
        <GenericError
          title="Ops! Link do formulário expirado"
          message="Este formulário encontra-se expirado.</br> Esta pesquisa não está mais recebendo coletas"
          statusCode={statusCode}
        />
      );
    case 202:
      return (
        <Container>
          <DraftMessage />
          <FinalizarPesquisa logoContratante={logo} isDraft />
        </Container>
      );
    case 419:
      return (
        <GenericError
          title="Coletas Excedidas"
          message="No momento não conseguimos receber mais coletas.</br> A meta estabelecida para esse formulário foi atingida."
          statusCode={statusCode}
        />
      );
    default:
      return (
        <GenericError
          statusCode={statusCode}
          title="Notamos uma instabilidade"
          message="Calma, guardamos com carinho todas as suas respostas.</br>
          Recarregue a página em alguns minutos, toda a nossa equipe está trabalhando para normalizar as coisas em breve."
        />
      );
  }
};

export { PaginaDeErro };
