import { Footer } from 'components/Footer';
import { CloudExclamation, Hourglass, SadFace, Target } from 'components/Icons';
import {
  Container,
  Content,
  Message,
  Title
} from './styles';

interface IGenericProps {
  title: string;
  message?: string;
  statusCode: number;
}

const renderIcon = (statusCode: number) => {
  switch (statusCode) {
    case 404:
      return <SadFace className='error-icon' />;
    case 422:
      return <Hourglass className='error-icon' />;
    case 419:
      return <Target className='error-icon' />;
    default:
      return <CloudExclamation className='error-icon' />;
  }
};

export const GenericError = ({
  title,
  message,
  statusCode,
}: IGenericProps) => {
  return (
    <Container>
      <Content>
        {renderIcon(statusCode)}
        <Title>{title}</Title>
        <Message dangerouslySetInnerHTML={{ __html: message ?? '' }} />
      </Content>
      <Footer />
    </Container>
  );
};
