import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  padding: 26px 16px 0 16px;
  /* height: calc(100vh - 91px); */

  @media only screen and (min-width: 768px) {
    padding: 48px 120px 0 120px;
  }
`;
