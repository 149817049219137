/* eslint-disable no-return-assign */
import { Button } from 'components';
import { Footer } from 'components/Footer';
import { LoadingSimple } from 'components/LoadingSimple';
import {
  Pergunta,
  TipoAlternativa,
  TipoPergunta,
} from 'core/pesquisa/Pesquisa';
import { RespostaPerguntaAggregate } from 'core/pesquisa/resposta/RespostaPesquisaAggregate';
import CoreContext from 'providers/core';
import { UIEvent, useContext, useEffect, useRef, useState } from 'react';
import { PerguntaEscalaNumerica } from 'sections/Question/PerguntaEscalaNumerica';
import { PerguntaFoto } from 'sections/Question/PerguntaFoto';
import { PerguntaMultipla } from 'sections/Question/PerguntaMultipla';
import { PerguntaSimples } from 'sections/Question/PerguntaSimples';
import '../../index.css';
import { PerguntaAberta } from './PerguntaAberta';
import { PerguntaData } from './PerguntaData';
import { PerguntaGradeMultipla } from './PerguntaGrade/PerguntaGradeMultipla';
import { PerguntaGradeUnica } from './PerguntaGrade/PerguntaGradeUnica';
import { PerguntaHorario } from './PerguntaHorario';
import { PerguntaVideo } from './PerguntaVideo';
import { PerguntaRef, genericRespostaAtual } from './interfaces';
import { Container, ContainerButtons, ContainerWrapper, Content, ContentWrapper, SubContent } from './styles';

enum Erro {
  OBRIGATORIA = 'OBRIGATORIA',
  MINIMA = 'MINIMA',
  MAXIMA = 'MAXIMA',
  VAZIO_TEXTO = 'VAZIO_TEXTO',
  VAZIO_NUMERO = 'VAZIO_NUMERO',
}

export enum InputErrors {
  NUMERO_MAX_LENGTH = 9,
  TEXTO_MAX_LENGTH = 255
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCorrespondingQuestionComponent(pergunta: Pergunta): any {
  switch (pergunta.tipo_pergunta) {
    case TipoPergunta.UNICA:
      if (
        pergunta.alternativas?.length === 1 &&
        pergunta.alternativas[0].tipo_alternativa ===
          TipoAlternativa.ABERTA_TEXTO
      ) {
        return [PerguntaAberta];
      }
      return [PerguntaSimples];
    case TipoPergunta.FOTO:
      return [PerguntaFoto];
    case TipoPergunta.VIDEO:
      return [PerguntaVideo];
    case TipoPergunta.MULTIPLA:
      return [PerguntaMultipla];
    case TipoPergunta.ESCALA_NUMERICA:
      return [PerguntaEscalaNumerica];
    case TipoPergunta.DATA:
      return [PerguntaData];
    case TipoPergunta.HORARIO:
      return [PerguntaHorario];
    case TipoPergunta.MULTIPLA_ORDEM_CITACAO:
      return [PerguntaMultipla];
    case TipoPergunta.GRADE_UNICA:
      return [PerguntaGradeUnica];
    case TipoPergunta.GRADE_MULTIPLA:
      return [PerguntaGradeMultipla];
    default:
      return null;
  }
}

interface Props {
  pergunta: Pergunta;
  idSecao: number;
  onResponse: (resposta: RespostaPerguntaAggregate) => void;
  onReturnQuestion: () => void;
  retornarParaInicioDaPesquisa: () => void;
  qtdeTotal: number;
  qtdeRespondida: number;
  respostaAtual: (id: number) => genericRespostaAtual | undefined;
  updateRespostaAtual: (respostaAtual: genericRespostaAtual) => void;
  isDraft: boolean;
}

const QuestionResolverComponent = (props: Props) => {
  const {
    pergunta,
    retornarParaInicioDaPesquisa,
    idSecao,
    onResponse,
    onReturnQuestion,
    qtdeTotal,
    qtdeRespondida,
    respostaAtual,
    updateRespostaAtual,
    isDraft
  } = props;
  const [Component] = getCorrespondingQuestionComponent(
    !pergunta.alternativas
      ? pergunta
      : {
          ...pergunta,
          alternativas: pergunta.alternativas.sort((a, b) => a.ordem - b.ordem),
        },
  );

  const { handleSetErrors, loading } = useContext(CoreContext);
  const componentRef = useRef<PerguntaRef>();
  const [scrollPosition, setScrollPosition] = useState({
    scrollX: false,
    scrollY: false,
    scrollMarginX: true,
    scrollMarginY: true,
  });

  // Atualiza o estado local para cada pergunta
  // que está sendo atualmente respondida.
  const updatePerguntaLocalState = () => {
    if (componentRef?.current) {
      const propriedadeDePersistencia = {
        key: '',
      };

      const isAbertaTexto =
        pergunta.alternativas?.length === 1 &&
        pergunta.alternativas[0].tipo_alternativa ===
          TipoAlternativa.ABERTA_TEXTO;

      if (isAbertaTexto) {
        propriedadeDePersistencia.key =
          pergunta.tipo_pergunta !== TipoPergunta.MULTIPLA
            ? 'texto'
            : 'alternativas';
      } else if (pergunta.tipo_pergunta === TipoPergunta.ESCALA_NUMERICA) {
        propriedadeDePersistencia.key = 'value';
      } else if (
        componentRef.current?.alternativas &&
        [
          TipoPergunta.MULTIPLA,
          TipoPergunta.MULTIPLA_ORDEM_CITACAO,
          TipoPergunta.UNICA,
        ].includes(pergunta.tipo_pergunta)
      ) {
        propriedadeDePersistencia.key = 'alternativas';
      } else if (
        pergunta.tipo_pergunta === TipoPergunta.DATA &&
        componentRef.current?.data
      ) {
        propriedadeDePersistencia.key = 'data';
      } else if (
        pergunta.tipo_pergunta === TipoPergunta.HORARIO &&
        componentRef.current?.horario
      ) {
        propriedadeDePersistencia.key = 'horario';
      } else if (
        [TipoPergunta.FOTO, TipoPergunta.VIDEO].includes(
          pergunta.tipo_pergunta,
        ) &&
        componentRef.current?.fotoBlob
      ) {
        propriedadeDePersistencia.key = 'fotoBlob';
      } else if (
        [TipoPergunta.GRADE_UNICA, TipoPergunta.GRADE_MULTIPLA].includes(
          pergunta.tipo_pergunta,
        ) &&
        componentRef.current?.alternativas_selecionadas_gradeLocal
      ) {
        propriedadeDePersistencia.key = 'alternativas_selecionadas_gradeLocal';
      }

      if (propriedadeDePersistencia.key) {
        updateRespostaAtual({
          pergunta,
          estadoAtual: componentRef.current[propriedadeDePersistencia.key],
        });
      }
    }
  };

  const buildRespostaPerguntaAgg = () => {
    if (componentRef.current?.alternativas) {
      const respostaPergunta = {
        perguntaComSecao: {
          pergunta,
          idSecao,
        },
        idPerguntaDestinoPulo: componentRef.current?.destinoPulo || -1,
        respostaPergunta: {
          id_pergunta: pergunta.id,
          tipo_pergunta: pergunta.tipo_pergunta,
          pulada: false,
          alternativas_selecionadas: componentRef.current?.alternativas,
        },
      };
      onResponse(respostaPergunta);
    } else if (pergunta.tipo_pergunta === TipoPergunta.DATA) {
      const respostaPergunta = {
        perguntaComSecao: {
          pergunta,
          idSecao,
        },
        idPerguntaDestinoPulo: componentRef.current?.destinoPulo || -1,
        respostaPergunta: {
          id_pergunta: pergunta.id,
          tipo_pergunta: pergunta.tipo_pergunta,
          pulada: false,
          data: componentRef.current?.data,
        },
      };

      onResponse(respostaPergunta);
    } else if (componentRef.current?.texto) {
      const respostaPergunta = {
        perguntaComSecao: {
          pergunta,
          idSecao,
        },
        idPerguntaDestinoPulo: componentRef.current?.destinoPulo || -1,
        respostaPergunta: {
          id_pergunta: pergunta.id,
          tipo_pergunta: pergunta.tipo_pergunta,
          pulada: false,
          texto: componentRef.current?.texto,
        },
      };

      onResponse(respostaPergunta);
    } else if (pergunta.tipo_pergunta === TipoPergunta.HORARIO) {
      const respostaPergunta = {
        perguntaComSecao: {
          pergunta,
          idSecao,
        },
        idPerguntaDestinoPulo: componentRef.current?.destinoPulo || -1,
        respostaPergunta: {
          id_pergunta: pergunta.id,
          tipo_pergunta: pergunta.tipo_pergunta,
          pulada: false,
          horario: componentRef.current?.horario,
        },
      };

      onResponse(respostaPergunta);
    } else if (
      pergunta.tipo_pergunta === TipoPergunta.FOTO ||
      pergunta.tipo_pergunta === TipoPergunta.VIDEO
    ) {
      const respostaPergunta = {
        perguntaComSecao: {
          pergunta,
          idSecao,
        },
        idPerguntaDestinoPulo: componentRef.current?.destinoPulo || -1,
        respostaPergunta: {
          id_pergunta: pergunta.id,
          tipo_pergunta: pergunta.tipo_pergunta,
          pulada: false,
          foto: componentRef.current?.foto,
        },
      };
      onResponse(respostaPergunta);
    } else if (componentRef.current?.alternativas_selecionadas) {
      const respostaPergunta = {
        perguntaComSecao: {
          pergunta,
          idSecao,
        },
        idPerguntaDestinoPulo: componentRef.current?.destinoPulo || -1,
        respostaPergunta: {
          id_pergunta: pergunta.id,
          tipo_pergunta: pergunta.tipo_pergunta,
          pulada: false,
          alternativas_selecionadas:
            componentRef.current?.alternativas_selecionadas,
        },
      };
      onResponse(respostaPergunta);
    }
  };

  const handleProximaPergunta = () => {
    handleSetErrors([]);
    let flagErro = false;
    // bars: bar[] = new Array();
    const err: Erro[] = [];
    let currentAlternative;
    let currentTipoAlternativa;

    // Validação para poder acessar os parâmetros sem conflito com tipagem
    if (componentRef.current) {
      currentAlternative = componentRef.current?.alternativas;
      currentTipoAlternativa = pergunta.alternativas;
    }
    /**
     * Inicialmente ocorre uma validação para saber se a pergunta é opcional
     * e o campo de seleção foi clicado .A segunda validação está relacionada
     * ao tipo de alternativa, pois a key do valor é diferente para cada tipo
     */
    if (!pergunta.obrigatoria && !componentRef.current?.vazia) {
      if (
        // Em perguntas do tipo FOTO ou VIDEO o atributo "alternativas" é undefined
        // por isso essa verificação a mais teve que ser adicionada.
        currentTipoAlternativa &&
        currentTipoAlternativa[0].tipo_alternativa === 'ABERTA_NUMERO' &&
        (currentAlternative.length === 0 ||
          currentAlternative[0].comportamento.length === 0)
      ) {
        err.push(Erro.VAZIO_NUMERO);
        flagErro = true;
      }

      if (
        currentTipoAlternativa &&
        currentTipoAlternativa[0].tipo_alternativa === 'ABERTA_TEXTO' &&
        (currentAlternative.length === 0 ||
          currentAlternative[0].comportamento.length === 0)
      ) {
        err.push(Erro.VAZIO_TEXTO);
        flagErro = true;
      }
    }

    if (componentRef.current?.vazia && pergunta.obrigatoria) {
      err.push(Erro.OBRIGATORIA);
      flagErro = true;
    }

    const arrayAlternativasExistentesInicializadas =
      pergunta.definicoes?.titulo_alternativas?.reduce(
        (acc: number[], item) => {
          acc[item.id] = -1;
          return acc;
        },
        [],
      ) ?? [];

    const arrayAlternativasSelecionadas =
      componentRef.current?.alternativas_selecionadas?.reduce(
        (acc: number[], item) => {
          if (acc[item.id_titulo_alternativa] !== -1) {
            // eslint-disable-next-line no-plusplus
            acc[item.id_titulo_alternativa]++;
            return acc;
          }
          acc[item.id_titulo_alternativa] = 1;
          return acc;
        },
        arrayAlternativasExistentesInicializadas,
      ) ?? [];

    if (
      TipoPergunta.GRADE_MULTIPLA &&
      componentRef.current?.alternativas_selecionadas &&
      pergunta.definicoes?.numero_minimo_respostas &&
      pergunta.obrigatoria
    ) {
      if (
        arrayAlternativasSelecionadas.some(
          item =>
            item !== undefined &&
            (pergunta?.definicoes?.numero_minimo_respostas ?? 0) > item,
        )
      ) {
        err.push(Erro.MINIMA);
        flagErro = true;
      }
    }

    if (
      TipoPergunta.GRADE_MULTIPLA &&
      componentRef.current?.alternativas_selecionadas &&
      pergunta.definicoes?.numero_maximo_respostas &&
      pergunta.obrigatoria
    ) {
      if (
        arrayAlternativasSelecionadas.some(
          item =>
            item !== undefined &&
            (pergunta?.definicoes?.numero_maximo_respostas ?? 0) < item,
        )
      ) {
        err.push(Erro.MAXIMA);
        flagErro = true;
      }
    }

    /**
     * Validação para perguntas unica do tipo 'ABERTA_TEXTO'
     */
    if (
      TipoPergunta.UNICA &&
      TipoAlternativa.ABERTA_TEXTO &&
      pergunta.alternativas?.length === 1
    ) {
      const getSelectAltLength =
      componentRef.current?.alternativas?.filter(item => {
        return item.tipo_alternativa === 'ABERTA_TEXTO' && item.texto;
      });

      if (getSelectAltLength && String(getSelectAltLength[0]?.texto)?.length > InputErrors.TEXTO_MAX_LENGTH) {
        flagErro = true;
      }
    }

    /**
     * Validação para perguntas unica do tipo 'ABERTA_NUMERO'
     */
    if (
      TipoPergunta.UNICA &&
      TipoAlternativa.ABERTA_NUMERO &&
      pergunta.alternativas?.length === 1
    ) {
      const getSelectAltLength =
      componentRef.current?.alternativas?.filter(item => {
        return item.tipo_alternativa === 'ABERTA_NUMERO' && item.numero;
      });

      if (getSelectAltLength && String(getSelectAltLength[0]?.numero)?.length > InputErrors.NUMERO_MAX_LENGTH) {
        flagErro = true;
      }
    }

    /**
     * validando a pergunta do tipo múltipla
     * validando perguntas do tipo aberta_texto e aberta_numero
     * quando o usuário marcar e não digitar nada
     * caso a pergunta seja obrigatória
     */
    if (
      TipoPergunta.MULTIPLA &&
      TipoAlternativa.ABERTA_NUMERO &&
      pergunta.obrigatoria
    ) {
      const calculateSelectAlterNumber =
        componentRef.current?.alternativas?.filter(item => {
          return item.tipo_alternativa === 'ABERTA_NUMERO' && !item.numero;
        });

      if (calculateSelectAlterNumber?.length) {
        err.push(Erro.VAZIO_NUMERO);
        flagErro = true;
      }

      const getSelectAltLength =
      componentRef.current?.alternativas?.filter(item => {
        return item.tipo_alternativa === 'ABERTA_NUMERO' && item.numero;
      });

      getSelectAltLength?.forEach((alt) => {
        if (String(alt.numero)?.length > InputErrors.NUMERO_MAX_LENGTH) {
          flagErro = true;
        }
      });
    }

    if (
      TipoPergunta.MULTIPLA &&
      TipoAlternativa.ABERTA_TEXTO &&
      pergunta.obrigatoria
    ) {
      const calculateSelectAlterText =
        componentRef.current?.alternativas?.filter(item => {
          return item.tipo_alternativa === 'ABERTA_TEXTO' && !item.texto;
        });

      if (calculateSelectAlterText?.length && !componentRef.current?.texto) {
        err.push(Erro.VAZIO_TEXTO);
        flagErro = true;
      }
    }

    /**
     * validando a pergunta do tipo múltipla
     * validando perguntas do tipo aberta_texto e aberta_numero
     * quando o usuário marcar e não digitar nada
     */
    if (TipoPergunta.MULTIPLA && TipoAlternativa.ABERTA_NUMERO) {
      if (pergunta.alternativas?.length !== 1) {
        const calculateSelectAlterNumber =
          componentRef.current?.alternativas?.filter(item => {
            return item.tipo_alternativa === 'ABERTA_NUMERO' && !item.numero;
          });

        if (calculateSelectAlterNumber?.length) {
          err.push(Erro.VAZIO_NUMERO);
          flagErro = true;
        }

        const getSelectAltLength =
        componentRef.current?.alternativas?.filter(item => {
          return item.tipo_alternativa === 'ABERTA_NUMERO' && item.numero;
        });

        getSelectAltLength?.forEach((alt) => {
          if (String(alt.numero)?.length > InputErrors.NUMERO_MAX_LENGTH) {
            flagErro = true;
          }
        });
      }
    }

    if (TipoPergunta.MULTIPLA && TipoAlternativa.ABERTA_TEXTO) {
      if (pergunta.alternativas?.length !== 1) {
        const calculateSelectAlterText =
          componentRef.current?.alternativas?.filter(item => {
            return item.tipo_alternativa === 'ABERTA_TEXTO' && !item.texto;
          });

        if (calculateSelectAlterText?.length && !componentRef.current?.texto) {
          err.push(Erro.VAZIO_TEXTO);
          flagErro = true;
        }

        const getSelectAltLength =
        componentRef.current?.alternativas?.filter(item => {
          return item.tipo_alternativa === 'ABERTA_TEXTO' && item.texto;
        });
  
        getSelectAltLength?.forEach((alt) => {
          if (String(alt.texto)?.length > InputErrors.TEXTO_MAX_LENGTH) {
            flagErro = true;
          }
        });
      }
    }

    /**
     * validando a pergunta do tipo múltipla
     */
    if (
      TipoPergunta.MULTIPLA &&
      componentRef.current?.alternativas &&
      pergunta.definicoes?.numero_maximo_respostas &&
      pergunta.obrigatoria
    ) {
      if (
        pergunta.definicoes.numero_maximo_respostas <
        componentRef.current.alternativas.length
      ) {
        err.push(Erro.MAXIMA);
        flagErro = true;
      }
    }

    if (
      TipoPergunta.MULTIPLA &&
      componentRef.current?.alternativas &&
      pergunta.definicoes?.numero_minimo_respostas &&
      pergunta.obrigatoria
    ) {
      if (
        pergunta.definicoes.numero_minimo_respostas >
        componentRef.current.alternativas.length
      ) {
        err.push(Erro.MINIMA);
        flagErro = true;
      }
    }

    /**
     * validando a pergunta do tipo múltipla ordem citação (que ainda será implementada)
     */
    if (
      TipoPergunta.MULTIPLA_ORDEM_CITACAO &&
      !!componentRef.current?.alternativas
    ) {
      // TODO refatorar para tirar esses ifs aninhados
      if (pergunta.definicoes?.qtd_maxima_respostas) {
        if (
          pergunta.definicoes?.qtd_maxima_respostas <
          componentRef.current?.alternativas?.length
        ) {
          err.push(Erro.MAXIMA);
          flagErro = true;
        }
      }
      if (pergunta.definicoes?.qtd_minima_respostas) {
        if (
          pergunta.definicoes?.qtd_minima_respostas >
          componentRef.current?.alternativas?.length
        ) {
          err.push(Erro.MINIMA);
          flagErro = true;
        }
      }
    }

    handleSetErrors(err);

    if (!flagErro) {
      buildRespostaPerguntaAgg();
      updatePerguntaLocalState();
    }
  };

  const handleVoltarPergunta = () => {
    handleSetErrors([]);

    if (pergunta?.ordem === 1) {
      retornarParaInicioDaPesquisa();
      return;
    }

    if (onReturnQuestion) {
      onReturnQuestion();
      updatePerguntaLocalState();
    }
  };

  const renderTitle = () => {
    if (qtdeTotal - 1 === qtdeRespondida) return 'Finalizar';
    if (qtdeTotal === qtdeRespondida) return '...';
    if (qtdeTotal - 1 !== qtdeRespondida) return 'Próximo';
    return '';
  };

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const {
      currentTarget: { scrollTop, scrollLeft },
    } = event;
    setScrollPosition({
      ...scrollPosition,
      scrollY: scrollTop !== 0,
      scrollX: scrollLeft !== 0,
    });
  };

  const gridRef = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    if (
      pergunta.tipo_pergunta === TipoPergunta.GRADE_MULTIPLA ||
      pergunta.tipo_pergunta === TipoPergunta.GRADE_UNICA
    ) {
      const grid = gridRef.current;
      setScrollPosition({
        ...scrollPosition,

        scrollMarginX: grid.scrollWidth > grid.clientWidth,

        scrollMarginY: grid.scrollHeight > grid.clientHeight,
      });
    }
  }, [pergunta, gridRef]);

  return (
    <Container isDraft={isDraft}>
      <ContainerWrapper isDraft={isDraft}>
        <ContentWrapper>
          <SubContent>
            <Content
              scrollMarginX={scrollPosition.scrollMarginX}
              scrollMarginY={scrollPosition.scrollMarginY}
              ref={gridRef}
              onScroll={handleScroll}
            >
              <Component
                key={`${idSecao}-${pergunta.id}`}
                pergunta={pergunta}
                idSecao={idSecao}
                respostaAtual={respostaAtual}
                ref={componentRef}
                ordemCitacao={
                  pergunta.tipo_pergunta === TipoPergunta.MULTIPLA_ORDEM_CITACAO
                }
                shadowControl={scrollPosition}
              />
            </Content>
          </SubContent>
          <ContainerButtons>
            <Button
              customButton="outline"
              onClick={handleVoltarPergunta}
              title="Voltar"
            />
            <Button
              customButton="default"
              onClick={handleProximaPergunta}
              title={renderTitle()}
            />
          </ContainerButtons>
        </ContentWrapper>
        
        <Footer />
      </ContainerWrapper>
      {/* renderizando o componente de loading */}
      {loading ? <LoadingSimple /> : null}
    </Container>
  );
};

export { QuestionResolverComponent };
