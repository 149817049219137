import { useState } from 'react';

/**
 * Valida se existe o valor do input ultrapassou o limite de caracteres
 */
export const useInputError = (): [boolean, (input: string, maxLength: number) => void] => {
  const [inputError, setInputError] = useState(false);  

  const validate = (input: string, maxLength: number): void => {
    if (input.length > maxLength) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };
  
  return [inputError, validate];
};
