import { Alternativa } from 'core/pesquisa/Pesquisa';
import React from 'react';

interface Props {
  alternativa: Alternativa;
  onChange: (checked: boolean) => void;
  checked: boolean;
}
const AlternativaOrdemSelecao: React.FC<Props> = ({
  alternativa,
  onChange,
  checked,
}: Props) => {
  const handleChange = ev => {
    if (onChange) onChange({ ...ev, checked: !checked, value: alternativa.id });
  };
  // const optionChecked = option.checked;
  return (
    <div className="justify-center font-roboto inline-flex">
      <div
        className="border border-white rounded-full w-28p h-28p bg-transparent"
        role="button"
        onClick={e => {
          handleChange(e);
        }}
        aria-hidden="true"
      >
        {checked && (
          <p className="text-xs pl-1.75 pt-5p pl-10p text-green-500">
            {alternativa.ordem}
          </p>
        )}
      </div>

      <label
        className="text-14 pl-4 font-roboto text-white"
        htmlFor={`${alternativa.id}`}
      >
        {alternativa.enunciado}
      </label>
    </div>
  );
};

export { AlternativaOrdemSelecao };
