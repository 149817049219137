import { Footer } from 'components/Footer';
import { Container, ContainerWrapper, Content, Message, Title } from './styles';

interface Props {
  logoContratante: string;
  isDraft: boolean;
}

export const FinalizarPesquisa = ({
  logoContratante,
  isDraft,
}: Props) => {
  return (
    <ContainerWrapper isDraft={isDraft}>
      <Container>
        <img 
          src={logoContratante} 
          alt="logo contratante" 
        />
        <Content>
          <Title>Agradecemos a sua participação!</Title>
          <Message>Ficamos muito gratos pela sua contribuição. Suas respostas serão analisadas em breve.</Message>
        </Content>
      </Container>
      <Footer />
    </ContainerWrapper>

  );
};
