import { ReactComponent as LoaderIcon } from 'assets/images/loader-icon.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 9999;

  background-color: rgba(0,0,0, 0.6);
  height: 100vh;
  width: 100vw;
`;

export const Icon = styled(LoaderIcon)`
  width: 44px;
  height: 43px;
  fill: ${({ theme }) => theme.colors.primary};
  animation: rotateImage 1.2s infinite;

  @keyframes rotateImage {
    to {
      transform: rotate(360deg);
    }
  }

  @media only screen and (min-width: 768px) {
    width: 74px;
    height: 70.63px;
  }
`;
