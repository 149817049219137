import { useInputError } from 'hooks/useInputError';
import React, {
  FocusEventHandler,
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { InputErrors } from 'sections/Question';
import {
  ClearIcon,
  Container,
  InputErrorMsg,
  NumberInput,
  SpanError,
  Wrapper,
} from './styles';

interface Props {
  initialValue: string;
  alternativeId: number;
  min?: number;
  max?: number;
  step?: number;
  placeholder: string;
  disabled: boolean;
  onChangeNumber?: (value: string) => void;
  onFocus: MouseEventHandler<HTMLDivElement>;
  onBlur: (event: FocusEventHandler<HTMLInputElement>, value: string) => void;
  errors?: Array<string>;
  checked?: boolean;
}

// Verificador para identificarmos posteriormente quais eventos deverão ser acionados
// ao incrementar ou decrementar os valores numéricos. Caso o usuário esteja em um dispositivo
// com sensibilidade ao toque, os eventos 'onTouchStart' serão reconhecidos pelo objeto window,
// e serão utilizados ao invés dos eventos de mouse, como "mouseDown" e "mouseUp".
const isMobile =
  'ontouchstart' in window ||
  (typeof navigator !== 'undefined' && navigator.maxTouchPoints);

const AlternativaAbertaNumero: React.FC<Props> = ({
  initialValue,
  alternativeId,
  onChangeNumber,
  onFocus,
  onBlur,
  placeholder,
  disabled,
  min = 0,
  step = 1,
  errors,
  checked
}: Props) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [inputError, validate] = useInputError(); // hook para controle dos inputErrors

  const persistValueProxy = useCallback(
    (value: string) => {
      let formattedValue = value;

      if (Number.isNaN(parseInt(value, 10))) {
        formattedValue = '';
      }

      validate(formattedValue, InputErrors.NUMERO_MAX_LENGTH);

      // Quantidade maxima aceita é 9, porém, permite a entrada de 10 para poder exibir a msg de erro
      if (formattedValue.length > InputErrors.NUMERO_MAX_LENGTH + 1) return;

      // Externaliza o novo valor à callback passada por props; isso atualizará a propriedade "value"
      // e irá persistir o ultimo valor no componente.
      if (onChangeNumber) onChangeNumber(formattedValue);
    },
    [onChangeNumber],
  );

  const handleBlur = ev => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      onBlur(ev, value);
    }
  };

  /**
   * Callbacks: Lida com o evento de mudança numérica do input, acionado quando o usuário
   * muda o valor do campo pelo teclado.
   */
  const handleOnValueChanges = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      persistValueProxy(event.target.value);
    },
    [onChangeNumber],
  );

  /**
   * Importante: Antes da refatoração do componente de alternativa tipo aberta numero,
   * ambas as alternativas, tipo aberta texto ou aberta numero possuiam um radio button,
   * e ao clicar em qualquer região do container da alternativa, era considerado que essa
   * alternativa tinha sido selecionada. Isso servia e serve para limpar as respostas das
   * demais alternativas da pergunta. Isto é um workaround: Como o novo componente não tem
   * mais essa lógica de "checked", inferimos ela aqui, basicamente, ao clicar em qualquer
   * região do componente, será interpretado que essa alternativa está em "checked = true",
   * então as demais alternativas terão seus valores resetados.
   */
  const getFocusEvents = function () {
    if (!checked) {
      return {
        [!isMobile ? 'onMouseDown' : 'onTouchStart']: onFocus,
      };
    } 
    return {};
  };

  // Limpa os valores setados no input numérico.
  const clearInput = function () {
    persistValueProxy('');
  };

  const withError =
    errors &&
    (errors.includes('VAZIO_NUMERO') || errors.includes('OBRIGATORIA')) &&
    !initialValue.length;

  useEffect(() => {
    if (!checked) {
      validate('', InputErrors.NUMERO_MAX_LENGTH);
    }
  });

  return (
    <Container>
      <label htmlFor={`${alternativeId}`}>{placeholder}</label>
      <Wrapper {...getFocusEvents()}>
        <NumberInput
          disabled={disabled}
          onBlur={handleBlur}
          value={initialValue}
          placeholder="00"
          min={min}
          ref={inputRef}
          step={step}
          type="number"
          onChange={handleOnValueChanges}
          withError={withError || inputError}
        />
        {typeof initialValue === 'string' && !!initialValue.length && (
          <ClearIcon onClick={() => clearInput()}>{'\u2715'}</ClearIcon>
        )}
      </Wrapper>
      {inputError && 
        <InputErrorMsg>Limite de { InputErrors.NUMERO_MAX_LENGTH } caracteres</InputErrorMsg> }
      {withError && <SpanError>Resposta obrigatória</SpanError>}
    </Container>
  );
};

export { AlternativaAbertaNumero };
