import { ReactComponent as LoaderIcon } from 'assets/images/loader-icon.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  height: 214px;
  width: 90%;
  z-index: 9999;

  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};

  border-radius: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 100px;
    max-width: 530px;
    width: 100%;
    max-height: 245.63px;
    height: 100%;
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  color: ${({ theme }) => theme.colors.gray400};

  @media only screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 55px;
  }
`;

export const Icon = styled(LoaderIcon)`
  width: 44px;
  height: 43px;
  fill: ${({ theme }) => theme.colors.primary};
  animation: rotateImage 1.2s infinite;

  @keyframes rotateImage {
    to {
      transform: rotate(360deg);
    }
  }

  @media only screen and (min-width: 768px) {
    width: 74px;
    height: 70.63px;
  }
`;

export const Circle = styled.span`
  animation: circleLoader 0.75s infinite;

  &:nth-child(1) {
    animation-delay: 0.25s;
  }
  &:nth-child(2) {
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    animation-delay: 0.75s;
  }

  @keyframes circleLoader {
    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }
`;
