import React from 'react';
import { ReactComponent as RelogioIcon } from '../../assets/images/relogio.svg';

interface Props {
  className: string;
}
const Relogio: React.FC<Props> = ({ className }: Props) => {
  return (
    <div className={`${className}`}>
      <RelogioIcon />
    </div>
  );
};

export { Relogio };
