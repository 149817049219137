import React from 'react';
import { ReactComponent as HourglassSVG } from '../../assets/icons/hourglass.svg';

const Hourglass: React.FC<{ className?: string }> = ({
  className,
}: {
  className?: string;
}) => {
  return <HourglassSVG className={className} />;
};

export { Hourglass };
