import { ICliente } from 'core/pesquisa/Pesquisa';
import { Container, Content, Contratante, SurveyTitle } from './styles';

interface Props {
  titulo: string;
  cliente: ICliente;
  logoContratante: string;
}

export const Header = ({
  titulo,
  cliente,
  logoContratante
}: Props) => {
  return (
    <Container>
      <Content>
        <Contratante>{ cliente?.nome }</Contratante>
        <SurveyTitle>{ titulo }</SurveyTitle>
      </Content>
      <img src={logoContratante} alt="logo contratante" />
    </Container>
  );
};
