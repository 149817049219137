import React from 'react';
import { ReactComponent as SquareCheckSVG } from '../../assets/images/square-check.svg';

const SquareCheck: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <SquareCheckSVG className={className} />;
};

export { SquareCheck };
