export class Pesquisa {
  id: number;
  versao: string;
  secoes: Secao[];
  titulo: string;
  descricao: string;
  cliente: ICliente;
  customizacaoContratante: ICustomizacaoContratante;
  status: string;
  arquivada: boolean;

  constructor(
    id: number,
    versao: string,
    secoes: Secao[],
    titulo: string,
    descricao: string,
    cliente: ICliente,
    customizacaoContratante: ICustomizacaoContratante,
    status: string,
    arquivada: boolean,
  ) {
    this.id = id;
    this.versao = versao;
    this.secoes = secoes;
    this.titulo = titulo;
    this.descricao = descricao;
    this.cliente = cliente;
    this.customizacaoContratante = customizacaoContratante;
    this.status = status;
    this.arquivada = arquivada;
  }
}

export class Secao {
  id: number;
  nome: string;
  descricao?: string;
  perguntas: { [key: number]: Pergunta };

  constructor(
    id: number,
    nome: string,
    perguntas: { [key: number]: Pergunta },
    descricao?: string,
  ) {
    this.id = id;
    this.nome = nome;
    this.descricao = descricao;
    this.perguntas = perguntas;
  }
}

export enum TipoPergunta {
  UNICA = 'UNICA',
  MULTIPLA = 'MULTIPLA',
  MULTIPLA_ORDEM_CITACAO = 'MULTIPLA_ORDEM_CITACAO',
  ESCALA_NUMERICA = 'ESCALA_NUMERICA',
  FOTO = 'FOTO',
  VIDEO = 'VIDEO',
  DATA = 'DATA',
  HORARIO = 'HORARIO',
  ABERTA = 'ABERTA',
  GRADE_UNICA = 'GRADE_UNICA',
  GRADE_MULTIPLA = 'GRADE_MULTIPLA',
}

export enum TipoComportamento {
  FINALIZAR_ENTREVISTA = 'FINALIZAR_ENTREVISTA',
  CONTINUAR_ENTREVISTA = 'CONTINUAR_ENTREVISTA',
}

export class Pergunta {
  id: number;
  enunciado: string;
  descricao?: string;
  obrigatoria: boolean;
  tipo_pergunta: TipoPergunta; // enum
  ordem: number;
  possui_alternativa_com_pulo?: boolean; // marcacao de alternativa (opcional)
  possui_diferente_de?: boolean; // marcacao de alternativa  (opcional)
  definicoes?: DefinicoesPergunta;
  alternativas?: Alternativa[];

  constructor(
    id: number,
    enunciado: string,
    obrigatoria: boolean,
    tipo_pergunta: TipoPergunta,
    ordem: number,
    possui_alternativa_com_pulo?: boolean,
    possui_diferente_de?: boolean,
    definicoes?: DefinicoesPergunta,
    alternativas?: Alternativa[],
    descricao?: string,
  ) {
    this.id = id;
    this.enunciado = enunciado;
    this.descricao = descricao;
    this.obrigatoria = obrigatoria;
    this.tipo_pergunta = tipo_pergunta;
    this.ordem = ordem;
    this.possui_alternativa_com_pulo = possui_alternativa_com_pulo;
    this.possui_diferente_de = possui_diferente_de;
    this.definicoes = definicoes;
    this.alternativas = alternativas;
  }
}

export interface TituloAlternativa {
  enunciado: string;
  id: number;
  ordem: number;
}

export interface ICliente {
  nome: string;
}

export interface ICustomizacaoContratante {
  tema: string;
  imagem: IImagemContratante
}

export interface IImagemContratante {
  logotipo_light: string;
}

export class DefinicoesPergunta {
  qtd_maxima_respostas?: number; // para perguntas de multipla escolha (opcional)
  qtd_minima_respostas?: number; // para perguntas de multipla escolha (opcional)
  intervalo_inicio?: number; // para perguntas de escala numerica (opcional)
  intervalo_fim?: number; // para perguntas de escala numerica (opcional)
  titulo_alternativas?: TituloAlternativa[]; // para as perguntas do tipo grade unica e multipla (opcional)
  numero_maximo_respostas?: number;
  numero_minimo_respostas?: number;

  constructor(
    qtd_maxima_respostas?: number,
    qtd_minima_respostas?: number,
    intervalo_inicio?: number,
    intervalo_fim?: number,
  ) {
    this.qtd_maxima_respostas = qtd_maxima_respostas;
    this.qtd_minima_respostas = qtd_minima_respostas;
    this.intervalo_inicio = intervalo_inicio;
    this.intervalo_fim = intervalo_fim;
  }
}

export enum TipoAlternativa {
  GRADE = 'GRADE',
  FECHADA = 'FECHADA',
  ABERTA_TEXTO = 'ABERTA_TEXTO',
  ABERTA_NUMERO = 'ABERTA_NUMERO',
}

export class Alternativa {
  id: number;
  enunciado: string;
  ordem: number;
  tipo_alternativa: TipoAlternativa;
  marcacao?: AlternativaMarcacao;
  comportamento?: string;

  constructor(
    id: number,
    enunciado: string,
    ordem: number,
    tipo_alternativa: TipoAlternativa,
    marcacao?: AlternativaMarcacao,
    comportamento?: string,
  ) {
    this.id = id;
    this.enunciado = enunciado;
    this.ordem = ordem;
    this.tipo_alternativa = tipo_alternativa;
    this.marcacao = marcacao;
    this.comportamento = comportamento;
  }
}

export class AlternativaMarcacao {
  id: number;
  id_pergunta_diferente?: number; // pergunta a qual as alternativas de resposta nao podem ser iguais
  id_pergunta_destino_pulo?: number; // pergunta a qual devemos pular, caso a alternativa atual seja selecionada como resposta

  constructor(
    id: number,
    id_pergunta_diferente?: number,
    id_pergunta_destino_pulo?: number,
  ) {
    this.id = id;
    this.id_pergunta_destino_pulo = id_pergunta_destino_pulo;
    this.id_pergunta_diferente = id_pergunta_diferente;
  }
}
