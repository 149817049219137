import styled from 'styled-components';

export const InputTitle = styled.h2`
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
`;

export const GrayInputTitle = styled(InputTitle)`
  color: ${({ theme }) => theme.colors.gray400};
`;

const InputBase = styled.div`
  display: flex;
  padding: 10px 32px;
  height: 46px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
`;

export const UploadInput = styled(InputBase)`
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const OutlineInput = styled(InputBase)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  .outline-input-check {
    fill: ${({ theme }) => theme.colors.primary};
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

export const ErrorInput = styled(InputBase)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.error200};
`;

export const ReloadBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 46px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;
