import { Loading } from 'components/Loading';
import { Container } from './style';

const PaginaDeLoading = () => {
  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default PaginaDeLoading;
