import styled, { css } from 'styled-components';

interface borderButton {
  hasBorder: boolean;
  hasUnderline: boolean;
  themeType?: 'different' | 'theme';
}

export const Container = styled.button<borderButton>`
  border-radius: 5px;
  padding: 2px 8px;
  align-self: center;
  margin-top: 10px;
  border: ${(props) => props.hasBorder ? '1px' : 'none'} solid
    ${({ theme }) => theme.colors.purple100};

  ${({ themeType }) =>
      themeType === 'different' 
      ? css`
        color: ${({ theme }) => theme.colors.gray400};
      `
      : css`
        color: ${({ theme }) => theme.colors.purple100};
      `
    }

  outline: none !important;

  &:hover {
    text-decoration: ${(props) => props.hasUnderline ? 'underline' : 'none'};
  }
`;
