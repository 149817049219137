import { AxiosResponse } from 'axios';
import { Pesquisa } from 'core/pesquisa/Pesquisa';
import { RespostaPesquisa } from 'core/pesquisa/resposta/RespostaPesquisa';
import Api from 'services/api';

export function getPesquisas(token: string): Promise<AxiosResponse<Pesquisa>> {
  const api = new Api(token);
  const result = api
    .getClient()
    .get(`/api/forms/questionario/friendly-token/${token}`);

  return result;
}

export function submitRespostaPesquisa(
  token: string,
  data,
): Promise<AxiosResponse<RespostaPesquisa>> {
  const api = new Api(token);

  const result = api
    .getClient()
    .post(`/api/forms/coletas/friendly-token?friendlyToken=${token}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  return result;
}

export function storeMedia(
  // token = token_pesquisa
  token: string,
  file: any,
): Promise<AxiosResponse<string>> {
  const api = new Api(token);
  const formData = new FormData();
  formData.append('file', file);

  const result = api.getClient().post(
    `/api/forms/medias?friendlyToken=${token}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return result;
}
