import { Circle, Container, Icon, Title } from './styles';

const Loading = () => {
  return (
    <Container>
      <Icon />
      <Title>
        Carregando Tensai Forms<Circle>.</Circle>
        <Circle>.</Circle>
        <Circle>.</Circle>
      </Title>
    </Container>
  );
};

export { Loading };
