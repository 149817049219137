import React from 'react';
import { ReactComponent as CloudExclamationSVG } from '../../assets/icons/cloud-exclamation.svg';

const CloudExclamation: React.FC<{ className?: string }> = ({
  className,
}: {
  className?: string;
}) => {
  return <CloudExclamationSVG className={className} />;
};

export { CloudExclamation };
