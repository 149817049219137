import React from 'react';
import { ReactComponent as SadFaceSVG } from '../../assets/icons/sad-face.svg';

const SadFace: React.FC<{ className?: string }> = ({
  className,
}: {
  className?: string;
}) => {
  return <SadFaceSVG className={className} />;
};

export { SadFace };
