import { ReactComponent as NewLogoTensai } from '../../assets/images/newLogo.svg';

interface ILogoProps {
  logoStyle?: string;
}

const NewLogo = ({ logoStyle }: ILogoProps) => {
  return <NewLogoTensai className={`${logoStyle}`} />;
};

export { NewLogo };
