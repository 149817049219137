import { Container } from './styles';

interface Props {
  title: string;
  onClick: () => void;
  hasBorder?: boolean;
  hasUnderline?: boolean;
   // ToDo: Refatorar para tornar igual ao componente <Item /> do analyze
  themeType?: 'different' | 'theme';
}

const OutlineButton = ({ title, onClick, hasBorder = true, themeType, hasUnderline = false }: Props) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <Container 
      type="button" 
      onClick={handleClick} 
      hasBorder={hasBorder}
      hasUnderline={hasUnderline}
      themeType={themeType}
    >
      {title}
    </Container>
  );
};

export { OutlineButton };
