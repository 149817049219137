import React from 'react';
import { ReactComponent as ReloadImg } from '../../assets/images/reload.svg';

const Reload: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <ReloadImg width="24px" height="19px" className={className} />;
};

export { Reload };
