import styled from 'styled-components';

export const UiFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 40px 0;
  .tensai-logo {  
    height: 34px;
  }

  @media only screen and (min-width: 426px) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const FooterText = styled.span`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 171px;

  @media only screen and (max-width: 425px) {
    text-align: center;
    font-size: 10px;
  }
`;
