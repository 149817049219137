import { Header, ProgressBar } from 'components';
import { DraftMessage } from 'components/DraftMessage';
import { TipoComportamento } from 'core/pesquisa/Pesquisa';
import { RespostaPerguntaAggregate } from 'core/pesquisa/resposta/RespostaPesquisaAggregate';
import CoreContext from 'providers/core';
import { useContext, useState } from 'react';
import { FinalizarPesquisa } from 'sections/FinalizarPesquisa';
import { StartSurveyBox } from 'sections/IniciarPesquisa';
import { QuestionResolverComponent } from 'sections/Question';
import { submitRespostaPesquisa } from 'services/pesquisa';
import { perguntaContemLogica } from 'util/pergunta';
import { msToTime } from 'util/time';
import { Container } from './styles';

const PesquisaPrincipal = () => {
  const { manipuladorPesquisa, token, setErrorState, handleSetLoading, logo } = useContext(CoreContext);
  const { titulo, descricao, cliente, status, arquivada } = manipuladorPesquisa.getPesquisa();
  const [pesquisaIniciada, setPesquisaIniciada] = useState(false);
  const [pesquisaFinalizada, setPesquisaFinalizada] = useState(false);
  const [perguntaSecaoAtual, setPerguntaSecaoAtual] = useState(
    manipuladorPesquisa.getPerguntaComSecaoAtual(),
  );
  const [qtdeTotalPergunta, setQtdeTotalPergunta] = useState(0);
  const [qtdePerguntaAtual, setQtdePerguntaAtual] = useState(0);
  const [tempoInicial, setTempoInicial] = useState(new Date());

  const onSubmit = (failure?) => {
    if (failure) {
      window.top?.postMessage(
        {
          fromTensai: true,
          failed: true,
          failure: {
            status: failure?.message?.status ?? failure?.status,
            message: failure?.message?.error ?? failure?.error,
          },
        },
        '*',
      );
    } else {
      window.top?.postMessage(
        {
          fromTensai: true,
          failed: false,
        },
        '*',
      );
    }

    /**
     * sending the message above
     */
  };
  const handleResponse = (respostaPerguntaAgg: RespostaPerguntaAggregate) => {
    const { respondidas, total } = manipuladorPesquisa.getProgresso();
    let alternativa;
    if (respostaPerguntaAgg) {
      alternativa = respostaPerguntaAgg.respostaPergunta;
      if (
        respondidas === total - 1 ||
        (perguntaContemLogica(
          respostaPerguntaAgg.perguntaComSecao.pergunta.tipo_pergunta,
        ) &&
          alternativa.alternativas_selecionadas[0]?.comportamento ===
            TipoComportamento.FINALIZAR_ENTREVISTA)
      ) {
        const tempoFinal = new Date();
        manipuladorPesquisa.responderPergunta(respostaPerguntaAgg);

        const respostaPesquisa = manipuladorPesquisa.completarPesquisa(
          `${msToTime(tempoFinal.getTime() - tempoInicial.getTime())}`,
          true,
        );

        // colocando a aplicação em modo de espera
        handleSetLoading(true);

        submitRespostaPesquisa(token, respostaPesquisa)
          .then(result => {
            if (result.status === 202) {
              const newError = { response: result };
              throw newError;
            }
            // informando que parou de carregar
            handleSetLoading(false);
            onSubmit();
            setPesquisaFinalizada(true);
          })
          .catch(err => {
            setErrorState({
              status: err.response.status,
              message: err.response.data,
              hasError: true,
              loading: false,
            });
            // informando que parou de carregar
            handleSetLoading(false);
            onSubmit(err.response.data);
          });
      } else {
        manipuladorPesquisa.responderEAvancarProximaPergunta(
          respostaPerguntaAgg,
        );
        setQtdeTotalPergunta(manipuladorPesquisa.getProgresso().total);
        setQtdePerguntaAtual(manipuladorPesquisa.getProgresso().respondidas);
      }
    }
    setPerguntaSecaoAtual(manipuladorPesquisa.getPerguntaComSecaoAtual());
  };

  const handleIniciarPesquisa = () => {
    setPesquisaIniciada(true);
    setTempoInicial(new Date());
    const { respondidas, total } = manipuladorPesquisa.getProgresso();
    setQtdeTotalPergunta(total);
    setQtdePerguntaAtual(respondidas);
  };

  const handleRetornarParaInicioDaPesquisa = () => {
    setPesquisaIniciada(false);
  };

  const isDraftSurvey = (): boolean => {
    return !!(status === 'RASCUNHO');
  };

  const handleReturnQuestion = () => {
    const previousQuestion =
      manipuladorPesquisa.voltarPerguntaAnterior().perguntaComSecao;
    try {
      setPerguntaSecaoAtual(previousQuestion);
      const { respondidas, total } = manipuladorPesquisa.getProgresso();
      setQtdeTotalPergunta(total);
      setQtdePerguntaAtual(respondidas);
    } catch (err) {
      onSubmit(err);
    }
  };

  const renderSection = () => {
    if (pesquisaFinalizada) {
      return <FinalizarPesquisa logoContratante={logo} isDraft={(isDraftSurvey() || arquivada)} />;
    }
    return pesquisaIniciada ? (
      <QuestionResolverComponent
        pergunta={perguntaSecaoAtual.pergunta}
        idSecao={perguntaSecaoAtual.idSecao}
        onResponse={handleResponse}
        onReturnQuestion={handleReturnQuestion}
        qtdeTotal={qtdeTotalPergunta}
        qtdeRespondida={qtdePerguntaAtual}
        retornarParaInicioDaPesquisa={handleRetornarParaInicioDaPesquisa}
        respostaAtual={manipuladorPesquisa.getRespostaAtualByPerguntaId}
        updateRespostaAtual={manipuladorPesquisa.updateRespostasLocais}
        isDraft={(isDraftSurvey() || arquivada)}
      />
    ) : (
      <StartSurveyBox
        iniciarPesquisa={handleIniciarPesquisa}
        titulo={titulo}
        descricao={descricao}
        cliente={cliente}
        logoContratante={logo}
        isDraft={(isDraftSurvey() || arquivada)}
      />
    );
  };

  return (
    <Container>
      {(isDraftSurvey() || arquivada) && 
      (
        <DraftMessage 
          surveyInProgress={pesquisaIniciada && !pesquisaFinalizada} 
        />
      )}
      {pesquisaIniciada && !pesquisaFinalizada && (
        <>
          <Header 
            titulo={titulo}
            cliente={cliente}
            logoContratante={logo}
          />
          <ProgressBar
            title={perguntaSecaoAtual.pergunta.enunciado}
            description={perguntaSecaoAtual.pergunta.descricao ?? ''}
            atualValue={qtdePerguntaAtual + 1}
            totalValue={qtdeTotalPergunta}
            required={perguntaSecaoAtual.pergunta.obrigatoria}
            minimumNumber={
              perguntaSecaoAtual.pergunta.definicoes?.numero_minimo_respostas
            }
            maximumNumber={
              perguntaSecaoAtual.pergunta.definicoes?.numero_maximo_respostas
            }
            questionType={perguntaSecaoAtual.pergunta.tipo_pergunta}
          />
        </>
      )}
      {renderSection()}
    </Container>
  );
};

export default PesquisaPrincipal;
