import { OutlineButton } from 'components/OutlineButton';
import { Pergunta, TipoAlternativa } from 'core/pesquisa/Pesquisa';
import { AlternativaSelecionada } from 'core/pesquisa/resposta/pergunta/aternativa/RespostaAlternativa';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { PerguntaRef, genericRespostaAtual } from '../interfaces';
import { CheckButton, Container, ContainerNumbers, NumberItem, NumericContainer } from './styles';

interface Props {
  pergunta: Pergunta;
  respostaAtual: (id: number) => genericRespostaAtual;
}

const PerguntaEscalaNumericaReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const { pergunta, respostaAtual } = props;
  const min = pergunta.definicoes?.intervalo_inicio;
  const max = pergunta.definicoes?.intervalo_fim;
  const [value, setValue] = useState<number | ''>('');
  const [alternativaSelecionada, setAlternativaSelecionada] =
    useState<AlternativaSelecionada | ''>({} as AlternativaSelecionada);
  const [idDestinoPulo, setIdDestinoPulo] = useState(0);

  useEffect(() => {
    const altEncontrada = pergunta.alternativas?.find(
      alt => Number(alt.enunciado) === value,
    );

    const idPerguntaDestinoPulo =
      altEncontrada?.marcacao?.id_pergunta_destino_pulo;
    if (idPerguntaDestinoPulo && idPerguntaDestinoPulo > 0) {
      setIdDestinoPulo(idPerguntaDestinoPulo);
    }

    if (altEncontrada) {
      setAlternativaSelecionada({
        id_alternativa: altEncontrada?.id || 0,
        ordem_selecao: 1,
        tipo_alternativa: TipoAlternativa.FECHADA,
        comportamento: altEncontrada?.comportamento,
      });
    } else {
      setAlternativaSelecionada('');
    }
  }, [pergunta.alternativas, value]);

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);

    if (respostaLocalAtual && respostaLocalAtual.estadoAtual) {
      const altEncontrada = pergunta.alternativas?.find(
        alter => Number(alter.enunciado) === respostaLocalAtual.estadoAtual,
      );

      setValue(Number(respostaLocalAtual.estadoAtual));

      const novaAlternativa = {
        id_alternativa: altEncontrada?.id || respostaLocalAtual.estadoAtual,
        ordem_selecao: 1,
        tipo_alternativa: TipoAlternativa.FECHADA,
        comportamento: altEncontrada?.comportamento,
      };

      setAlternativaSelecionada(novaAlternativa);
    }
  }, [pergunta.alternativas, pergunta.id, respostaAtual]);

  useImperativeHandle(ref, () => {
    return {
      alternativas: alternativaSelecionada ? [alternativaSelecionada] : [],
      data: null,
      horario: null,
      foto: null,
      alternativas_selecionadas: null,
      texto: null,
      destinoPulo:
      alternativaSelecionada && alternativaSelecionada.comportamento === 'PULAR_PARA_PERGUNTA'
          ? idDestinoPulo
          : null,
      vazia: alternativaSelecionada === '',
      value,
    };
  });

  const handleChange = (newValue: number) => {
    setValue(newValue);

    const altEncontrada = pergunta.alternativas?.find(
      alter => Number(alter.enunciado) === newValue,
    );

    setAlternativaSelecionada({
      id_alternativa: altEncontrada?.id || newValue,
      ordem_selecao: 1,
      tipo_alternativa: TipoAlternativa.FECHADA,
      comportamento: altEncontrada?.comportamento,
    });
  };

  const numericList: number[] = max ? Array.from({ length: max }, (_, index) => index + 1) : [];
  const renderInputs = (arr: number[]): number[] => {
    if (!!max && min === 0) {
      arr.unshift(0);
    }
   return arr;
  };
  
  return (
    <Container>
      <NumericContainer checked={(value !== '')}>
        {renderInputs(numericList).map((item) => {
          return (
            <ContainerNumbers>
              <NumberItem checked={item === value}>
                {item}
              </NumberItem>
              <CheckButton
                key={item}
                checked={item === value}
                role="button"
                onClick={() => handleChange(item)}
              />
            </ContainerNumbers>
          );
        })}
      </NumericContainer>
      {value !== '' && 
        (
        <OutlineButton
          title='Limpar seleção' 
          onClick={() => setValue('')}
          hasBorder={false}
          themeType='different'
        />
        )}
    </Container>
  );
};

const PerguntaEscalaNumerica = forwardRef(PerguntaEscalaNumericaReferenciable);
export { PerguntaEscalaNumerica };
