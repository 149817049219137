import { Redirect, Route, Switch } from 'react-router-dom';
// pages
import Main from 'pages/Main';

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/forms/:token" component={Main} />
      <Route exact path="/forms" component={Main} />
      <Redirect exact from="/" to="/forms" />
    </Switch>
  );
}
