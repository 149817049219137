import CoreContext from 'providers/core';
import { useContext } from 'react';

import {
  Container,
  ContentLabels,
  ContentText,
  Description,
  Label,
  LineBar,
  Pages,
  Progress,
  Title,
} from './styles';

enum Erro {
  OBRIGATORIA = 'OBRIGATORIA',
  MINIMA = 'MINIMA',
  MAXIMA = 'MAXIMA',
  VAZIO_TEXTO = 'VAZIO_TEXTO',
  VAZIO_NUMERO = 'VAZIO_NUMERO',
}

interface Props {
  atualValue: number;
  totalValue: number;
  title: string;
  description: string;

  required: boolean;
  minimumNumber: number | undefined;
  maximumNumber: number | undefined;
  questionType: string;
}

const ProgressBar = ({
  atualValue,
  totalValue,
  title,
  description,
  required,
  minimumNumber,
  maximumNumber,
  questionType,
}: Props) => {
  const { errosValidationQuestions } = useContext(CoreContext);

  const singularLabel =
    questionType === 'GRADE_UNICA' || questionType === 'GRADE_MULTIPLA'
      ? 'opção por linha'
      : 'opção';

  const pluralLabel =
    questionType === 'GRADE_UNICA' || questionType === 'GRADE_MULTIPLA'
      ? 'opções por linha'
      : 'opções';

  return (
    <Container>
      <Progress>
        <LineBar widthProgress={(100 * atualValue) / totalValue} />
        <Pages>
          Pergunta {atualValue} de {totalValue}
        </Pages>
      </Progress>
      <ContentText>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ContentLabels>
          {required && (
            <Label
              error={errosValidationQuestions.some(
                err => Erro.OBRIGATORIA === err,
              )}
            >
              Pergunta obrigatória
            </Label>
          )}
          {minimumNumber && (
            <Label
              error={errosValidationQuestions.some(err => Erro.MINIMA === err)}
            >
              {`Mínimo ${
                minimumNumber > 1
                  ? `${minimumNumber} ${pluralLabel}`
                  : `${minimumNumber} ${singularLabel}`
              }`}
            </Label>
          )}

          {maximumNumber && (
            <Label
              error={errosValidationQuestions.some(err => Erro.MAXIMA === err)}
            >
              {`Máximo ${
                maximumNumber > 1
                  ? `${maximumNumber} ${pluralLabel}`
                  : `${maximumNumber} ${singularLabel}`
              }`}
            </Label>
          )}
        </ContentLabels>
      </ContentText>
    </Container>
  );
};

export { ProgressBar };
