import { DefaultTheme } from 'styled-components';

const themes = {
  default: {
    primary: '#3251EA',
    secondary: '#2CA0F4',
    tertiary: '#EAF6FE',
    colorIcon: '#2CA0F4',
    dark: '#2841BB',
    menu: '#12004A',
  },
  verde: {
    primary: '#00642C',
    secondary: '#078C09',
    tertiary: '#DBF3E0',
    colorIcon: '#078C09',
    dark: '#015426',
    menu: '#00642C',
  },
  laranja: {
    primary: '#F27405',
    secondary: '#F29F05',
    tertiary: '#FDF0D8',
    colorIcon: '#F29F05',
    dark: '#D86500',
    menu: '#F27405',
  },
  vermelho: {
    primary: '#D91E2E',
    secondary: '#F22E3E',
    tertiary: '#FFE6E8',
    colorIcon: '#F22E3E',
    dark: '#C31C2B',
    menu: '#D91E2E',
  },
  amarelo: {
    primary: '#F1B60D',
    secondary: '#F2CB05',
    tertiary: '#FBF8D7',
    colorIcon: '#F2CB05',
    dark: '#E1AB0E',
    menu: '#F1B60D',
  }
};

const defaultColors = {
  // neutros
  gray100: '#F8FAFB',
  gray200: '#DFDFDF',
  gray300: '#6B7986',
  gray400: '#495F6E',
  gray500: '#202428',
  white: '#FFFFFF',

  // alertas
  success100: '#f2fcfc',
  success200: '#007a80',
  alert100: '#FFECCF',
  alert200: '#ca8b41',
  error100: '#FFE5EA',
  error200: '#b31733',
  statusError: '#eb5757',
  statusSuccess: '#04bd00',
  statusInProgress: '#57b6eb',
  statusWarning: '#F5D665',

  // apagar no futuro
  red: '#eb5757',
  purple100: '#61008A',
  hoverPurple: '#36004C',
  textInputColor: '#202428',
};

export const mapTheme = (themeString: string): DefaultTheme => {
  return {
    title: themeString,
    colors: {
      ...defaultColors,
      ...themes[themeString],
    },
  };
};
