import { RespostaPergunta } from './pergunta/RespostaPergunta';

class RespostaPesquisa {
  versao_pesquisa: string;
  tempo_duracao: string;
  secoes: RespostaSecao[];

  constructor(
    versao_pesquisa: string,
    tempo_duracao: string,
    secoes: RespostaSecao[],
  ) {
    this.versao_pesquisa = versao_pesquisa;
    this.tempo_duracao = tempo_duracao;
    this.secoes = secoes;
  }
}

class RespostaSecao {
  id_secao: number;
  perguntas: RespostaPergunta[];

  constructor(id_secao: number, perguntas: RespostaPergunta[]) {
    this.id_secao = id_secao;
    this.perguntas = perguntas;
  }
}

export { RespostaPesquisa, RespostaSecao };
