import { perguntaContemLogica } from 'util/pergunta';
import { Pesquisa, TipoComportamento, TipoPergunta } from './Pesquisa';
import { getRespostaPerguntaByTipoPergunta } from './resposta/pergunta/respostaPerguntaFactory';
import RespostaPesquisaFactory from './resposta/RespostaPerguntaFactory';
import { RespostaPesquisa } from './resposta/RespostaPesquisa';
import {
  PerguntaComSecao,
  RespostaPerguntaAggregate
} from './resposta/RespostaPesquisaAggregate';

export type ProgressoPesquisa = { total: number; respondidas: number };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type respostasLocaisGenericas = { [key: string]: any };

export interface ManipuladorPesquisa {
  voltarPerguntaAnterior(): RespostaPerguntaAggregate;
  responderEAvancarProximaPergunta(
    resposta: RespostaPerguntaAggregate,
  ): RespostaPerguntaAggregate;
  completarPesquisa(
    tempoDuracao: string,
    checarFinalizacao?: boolean,
  ): RespostaPesquisa;
  getProgresso(): ProgressoPesquisa;
  responderPergunta(resposta: RespostaPerguntaAggregate): void;
  getPerguntaComSecaoAtual(): PerguntaComSecao;
  getPesquisa(): Pesquisa;
  updateRespostasLocais(resposta: respostasLocaisGenericas): void;
  getRespostaAtualByPerguntaId(
    id: number,
  ): respostasLocaisGenericas | undefined;
}

export class DefaultManipuladorPesquisa implements ManipuladorPesquisa {
  private readonly pesquisa: Pesquisa;
  private readonly respostaPesquisaFactory: RespostaPesquisaFactory;
  private readonly ordemPerguntaToPerguntaMap: Map<number, PerguntaComSecao>;
  private readonly idPerguntaToPerguntaMap: Map<number, PerguntaComSecao>;
  private readonly perguntasRespondidas: RespostaPerguntaAggregate[];
  private readonly perguntasPuladas: Map<number, RespostaPerguntaAggregate>;
  private ordemMaxima!: number;
  private perguntaComSecaoAtual!: PerguntaComSecao;
  private totalPerguntasPossivel!: number;
  private totalPerguntasAtual!: number;
  private ordemAtual: number;

  private respostasEmEstadoAtual: respostasLocaisGenericas[];

  constructor(pesquisa: Pesquisa) {
    this.pesquisa = pesquisa;
    this.respostaPesquisaFactory = new RespostaPesquisaFactory();
    this.perguntasRespondidas = [];
    this.respostasEmEstadoAtual = [];
    this.perguntasPuladas = new Map();
    this.ordemPerguntaToPerguntaMap = new Map();
    this.idPerguntaToPerguntaMap = new Map();
    this.ordemAtual = 1;
    this.ordemMaxima = 0;
    if (pesquisa.secoes) {
      const newData = pesquisa.secoes.map(section => {
        const survey = Object.values(section.perguntas);
        return survey.sort((a, b) => a.ordem - b.ordem);
      });

      this.perguntaComSecaoAtual = {
        idSecao: pesquisa.secoes[0].id,
        pergunta: pesquisa.secoes[0].perguntas[newData[0][0].id],
      };

      this.init();
    }
  }

  private init = () => {
    this.totalPerguntasPossivel = 0;

    this.pesquisa.secoes.forEach(secao => {
      const perguntas = Object.values(secao.perguntas);

      perguntas.sort((a, b) => a.ordem - b.ordem);

      perguntas.forEach(pergunta => {
        this.totalPerguntasPossivel += 1;
        const perguntaComSecao = new PerguntaComSecao(pergunta, secao.id);
        this.ordemPerguntaToPerguntaMap.set(pergunta.ordem, perguntaComSecao);
        this.idPerguntaToPerguntaMap.set(pergunta.id, perguntaComSecao);

        if (this.ordemMaxima < pergunta.ordem)
          this.ordemMaxima = pergunta.ordem;
      });

      this.totalPerguntasAtual = this.totalPerguntasPossivel;
      return perguntas;
    });
  };

  private atualizarTotalPerguntas = () => {
    this.totalPerguntasAtual =
      this.totalPerguntasPossivel - this.perguntasPuladas.values.length;
  };

  getPerguntaComSecaoAtual = (): PerguntaComSecao => {
    return this.perguntaComSecaoAtual;
  };

  getProgresso = (): ProgressoPesquisa => {
    return {
      total: this.totalPerguntasAtual,
      respondidas:
        this.perguntasRespondidas.length + this.perguntasPuladas.size,
    };
  };

  getPesquisa = (): Pesquisa => {
    return this.pesquisa;
  };

  voltarPerguntaAnterior = (): RespostaPerguntaAggregate => {
    const perguntaAnterior = this.perguntasRespondidas.pop();
    if (!perguntaAnterior) {
      throw new Error('Nao há pergunta anterior');
    }
    this.removerPerguntasPuladasEntreOrdens(
      perguntaAnterior.perguntaComSecao.pergunta.ordem,
      this.ordemAtual,
    );

    this.atualizarTotalPerguntas();
    this.perguntaComSecaoAtual = perguntaAnterior.perguntaComSecao;
    this.ordemAtual = perguntaAnterior.perguntaComSecao.pergunta.ordem;
    return perguntaAnterior;
  };

  removeLineBreak = resposta => {
    const alternativasSelecionadas =
      resposta.respostaPergunta.alternativas_selecionadas;

    if (alternativasSelecionadas) {
      resposta.respostaPergunta.alternativas_selecionadas = alternativasSelecionadas.map(a => {
        if (a.texto) {
          return {
            ...a,
            texto: a.texto.replace(/(\r\n|\n|\r)/gm, ''),
          };
        }
        return { ...a };
      });
    }
    return resposta;
  };

  responderPergunta = (resposta: RespostaPerguntaAggregate): void => {
    const answer = this.removeLineBreak(resposta);
    this.perguntasRespondidas.push(answer);
  };

  responderEAvancarProximaPergunta = (
    resposta: RespostaPerguntaAggregate,
  ): RespostaPerguntaAggregate => {
    this.responderPergunta(resposta);

    let proximaPergunta: PerguntaComSecao;
    let alternativaSelecionada;

    // Criado validação para conseguir acessar alternativas_selecionadas sem erro na tipagem
    if (resposta.respostaPergunta) {
      alternativaSelecionada = resposta.respostaPergunta;
    }

    if (
      perguntaContemLogica(resposta.perguntaComSecao.pergunta.tipo_pergunta) &&
      alternativaSelecionada.alternativas_selecionadas[0]?.comportamento ===
        TipoComportamento.FINALIZAR_ENTREVISTA
    ) {
      proximaPergunta = <PerguntaComSecao>(
        this.idPerguntaToPerguntaMap.get(this.ordemMaxima)
      );

      this.addPerguntasPuladasEntreOrdens(this.ordemAtual, this.ordemMaxima);
    } else if (
      resposta.perguntaComSecao.pergunta.possui_alternativa_com_pulo &&
      resposta.idPerguntaDestinoPulo !== -1
    ) {
      proximaPergunta = <PerguntaComSecao>(
        this.idPerguntaToPerguntaMap.get(<number>resposta.idPerguntaDestinoPulo)
      );

      this.addPerguntasPuladasEntreOrdens(
        this.ordemAtual,
        proximaPergunta.pergunta.ordem,
      );
    } else {
      let proximaOrdem = this.ordemAtual + 1;

      if (proximaOrdem > this.ordemMaxima) {
        proximaOrdem = this.ordemMaxima;
      }

      proximaPergunta = <PerguntaComSecao>(
        this.ordemPerguntaToPerguntaMap.get(proximaOrdem)
      );
    }

    const novaPerguntaAtual = new RespostaPerguntaAggregate(proximaPergunta);

    if (proximaPergunta) {
      if (
        perguntaContemLogica(
          resposta.perguntaComSecao.pergunta.tipo_pergunta,
        ) &&
        alternativaSelecionada.alternativas_selecionadas[0]?.comportamento ===
          TipoComportamento.FINALIZAR_ENTREVISTA
      ) {
        this.atualizarTotalPerguntas();
        this.ordemAtual = this.ordemMaxima;
      } else {
        this.atualizarTotalPerguntas();
        this.perguntaComSecaoAtual = novaPerguntaAtual.perguntaComSecao;
        this.ordemAtual = novaPerguntaAtual.perguntaComSecao.pergunta.ordem;
      }
    }

    return novaPerguntaAtual;
  };

  completarPesquisa = (
    tempoDuracao: string,
    checarFinalizacao = false,
  ): RespostaPesquisa => {
    // Adiciona as perguntas puladas no payload final quando a pesquisa for finalizada por
    // comportamento = finalizar_pesquisa
    if (checarFinalizacao && this.ordemAtual !== this.ordemMaxima) {
      this.addPerguntasPuladasEntreOrdens(
        this.ordemAtual,
        1 + this.ordemMaxima,
      );
    }
    // Recuperando as perguntas puladas com secao
    const perguntasPuladasAgg = Array.from(this.perguntasPuladas.values());
    // Adicionando as perguntas puladas ao array final
    const perguntasRespondidas = [
      ...this.perguntasRespondidas,
      ...perguntasPuladasAgg,
    ];

    const respostaPesquisa = this.respostaPesquisaFactory.getInstance(
      this.pesquisa.versao,
      tempoDuracao,
      perguntasRespondidas,
    );
    return respostaPesquisa;
  };

  // Caso a pergunta / alternativa contenha lógica de pulo
  // esse método irá setar o id de destino de pulo no objeto
  // de armazenamento do estado de resposta atual. Isso serve
  // para que o id de destino de pulo da resposta selecionada
  // não seja perdido quando o usuário "voltar" para a pergunta
  // anterior.
  updatePuloPerguntaLocalState = (
    respostaLocalAtual: respostasLocaisGenericas,
  ) => {
    // Verifica se o objeto verificado é real
    const isRealObject = (o): boolean => o && typeof o === 'object';
    // Verifica se a resposta passada por parâmetro é um objeto válido
    // e se ele possui um estadoAtual armazenado.
    if (isRealObject(respostaLocalAtual) && !!respostaLocalAtual.estadoAtual) {
      // Cria uma cópia da resposta local para que possamos alterá-la e retorná-la posteriormente.
      const respostaLocalAtualCopy = { ...respostaLocalAtual };

      const { pergunta, estadoAtual } = respostaLocalAtualCopy;
      // No momento, podemos configurar lógica para perguntas do tipo UNICA e ESCALA_NUMERICA
      const possuiLogicaEmAlternativas =
        pergunta.tipo_pergunta === TipoPergunta.UNICA ||
        pergunta.tipo_pergunta === TipoPergunta.ESCALA_NUMERICA;

      if (
        possuiLogicaEmAlternativas &&
        Array.isArray(estadoAtual) &&
        estadoAtual.length
      ) {
        // Recupera a alternativa selecionada pelo usuário.
        const alternativaComLogicaSelecionada = pergunta.alternativas.find(
          alternativa => alternativa.id === estadoAtual[0].id_alternativa,
        );

        let idDestinoPulo = 0;
        // Verifica se a alternativa selecionada pelo usuário é valida e contém
        // lógica de pulo
        if (
          alternativaComLogicaSelecionada &&
          !!alternativaComLogicaSelecionada.marcacao &&
          alternativaComLogicaSelecionada.marcacao.id_pergunta_destino_pulo > 0
        ) {
          // Seta o id do destino de pulo da alternativa
          idDestinoPulo =
            alternativaComLogicaSelecionada.marcacao.id_pergunta_destino_pulo;
        }
        respostaLocalAtualCopy.idPerguntaDestinoPulo = idDestinoPulo;
      }

      // Objeto com lógica de pulo persistida
      return respostaLocalAtualCopy;
    }

    // Não encontrou nenhuma lógica de pulo que deve ser setada, portanto, retorna
    // o objeto sem nenhuma modificação.
    return respostaLocalAtual;
  };

  // Atualiza as respostas para cada pergunta de forma local, o método é chamado
  // quando um usuário avança ou retrocede uma pergunta.
  updateRespostasLocais = (respostaAtual: respostasLocaisGenericas): void => {
    const respostaAtualProcessada =
      this.updatePuloPerguntaLocalState(respostaAtual);
    const respostaPerguntaAtualIndex = this.respostasEmEstadoAtual.findIndex(
      resposta => resposta.pergunta.id === respostaAtualProcessada.pergunta.id,
    );

    if (respostaPerguntaAtualIndex === -1) {
      this.respostasEmEstadoAtual.push(respostaAtualProcessada);
    } else {
      this.respostasEmEstadoAtual[respostaPerguntaAtualIndex] =
        respostaAtualProcessada;
    }
  };

  // Método utilizado para recuperar o estado local armazenado em memória para cada pergunta
  getRespostaAtualByPerguntaId = (
    id: number,
  ): respostasLocaisGenericas | undefined => {
    return this.respostasEmEstadoAtual.find(respostaLocal => {
      if (
        respostaLocal &&
        respostaLocal.pergunta &&
        respostaLocal.pergunta.id
      ) {
        return respostaLocal.pergunta.id === id;
      }

      return false;
    });
  };

  private removerPerguntasPuladasEntreOrdens = (
    ordemInicio: number,
    ordemFim: number,
  ) => {
    for (
      let ordemParaRemover = ordemInicio;
      ordemParaRemover < ordemFim;
      ordemParaRemover += 1
    ) {
      const perguntaPuladaParaRemover = <PerguntaComSecao>(
        this.ordemPerguntaToPerguntaMap.get(ordemParaRemover)
      );
      this.perguntasPuladas.delete(perguntaPuladaParaRemover.pergunta.id);
    }
  };

  private addPerguntasPuladasEntreOrdens = (
    ordemInicio: number,
    ordemFim: number,
  ) => {
    for (
      let ordemParaPular = ordemInicio + 1;
      ordemParaPular < ordemFim;
      ordemParaPular += 1
    ) {
      const perguntaComSecaoParaPular = <PerguntaComSecao>(
        this.ordemPerguntaToPerguntaMap.get(ordemParaPular)
      );

      const { id: idPergunta, tipo_pergunta } =
        perguntaComSecaoParaPular.pergunta;

      // Recupera a resposta da pergunta de acordo com seu tipo de pergunta
      // pela seguinte diferenciação: Em perguntas do tipo UNICA, por exemplo,
      // o backend espera o atributo "alternativas_selecionadas: []" para perguntas
      // puladas, já em uma pergunta do tipo FOTO, o backend espera o atributo "foto": null
      const respostaPergunta = getRespostaPerguntaByTipoPergunta(
        idPergunta,
        tipo_pergunta,
        true,
        undefined,
      );

      const respostaPerguntaAgg = new RespostaPerguntaAggregate(
        perguntaComSecaoParaPular,
        respostaPergunta,
      );

      this.perguntasPuladas.set(
        perguntaComSecaoParaPular.pergunta.id,
        respostaPerguntaAgg,
      );
    }
  };
}
