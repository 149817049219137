import { TipoPergunta } from 'core/pesquisa/Pesquisa';

/**
 *
 * @param tipoPerguntaAtual - Tipo da pergunta (UNICA, MULTIPLA, GRADE_UNICA, ..., TipoN)
 * @returns true caso segundo a regra de negócio, seja possível configurar
 * lógica para esse tipo de pergunta.
 *
 */
export function perguntaContemLogica(tipoPerguntaAtual: TipoPergunta) {
  if (
    // eslint-disable-next-line operator-linebreak
    tipoPerguntaAtual === TipoPergunta.UNICA ||
    tipoPerguntaAtual === TipoPergunta.ESCALA_NUMERICA
  ) {
    return true;
  }
  return false;
}
