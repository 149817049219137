import React from 'react';
import { ReactComponent as ExclamationSVG } from '../../assets/icons/exclamation.svg';

const Exclamation: React.FC<{ className?: string }> = ({
  className,
}: {
  className?: string;
}) => {
  return <ExclamationSVG className={className} />;
};

export { Exclamation };
