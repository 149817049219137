import React from 'react';
import { ReactComponent as CircleDotSVG } from '../../assets/images/circle-dot.svg';

const CircleDot: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <CircleDotSVG className={className} />;
};

export { CircleDot };
