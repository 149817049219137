import { Container } from './styles';

interface Props {
  title: string;
  customButton?: 'outline' | 'default';
  className?: string;
  onClick: () => void;
}

const Button = ({
  title,
  onClick,
  customButton = 'outline',
  ...restProps
}: Props) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Container
      type="button"
      onClick={handleClick}
      customButton={customButton}
      {...restProps}
    >
      {title}
    </Container>
  );
};

export { Button };
