import React from 'react';
import { ReactComponent as ImgUploadSvg } from '../../assets/images/imgUpload.svg';

const ImgUpload: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <ImgUploadSvg width="16px" height="14px" className={className} />;
};

export { ImgUpload };
