import styled, { css } from 'styled-components';

interface ILineBar {
  widthProgress: number;
}

interface ILabel {
  error: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px 6px 16px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};

  @media only screen and (min-width: 768px) {
    padding: 32px;
  }
`;

export const Progress = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media only screen and (max-width: 425px) {
    gap: 8px
  }
`;

export const LineBar = styled.div<ILineBar>`
  position: relative;
  background: ${({ theme }) => theme.colors.tertiary};
  border-radius: 8px;
  width: 229px;
  height: 8px;

  &::before {
    content: '';
    position: absolute;
    border-radius: 8px;
    width: ${props => props.widthProgress}%;
    background-color: ${({ theme }) => theme.colors.primary};
    height: 8px;
  }

  @media only screen and (max-width: 425px) {
    width: 121px;
    &::before {
      height: 6px;
    }
  }

  @media only screen and (min-width: 768px) {
    height: 8px;

    &::before {
      height: 8px;
    }
  }
`;

export const Pages = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.gray400};

  @media only screen and (min-width: 768px) {
    font-size: 12px;
    line-height: 21px;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-row: 1;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.gray400};

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding-top: 16px;
  }
`;

export const Description = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.gray300};

  @media only screen and (min-width: 768px) {
    padding-bottom: 16px;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ContentLabels = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Label = styled.span<ILabel>`
  display: flex;
  align-items: center;

  border: 1px solid
    ${({ theme }) => theme.colors.gray200};
  border-radius: 20px;
  padding: 8px;
  width: fit-content;
  height: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.gray400};
  font-family: 'Open Sans';


  ${props =>
    props.error &&
    css`
      border: solid 1px ${({ theme }) => theme.colors.error100};
      color: ${({ theme }) => theme.colors.statusError};
    `}
`;
