import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 40px 120px 0 120px;

  @media only screen and (max-width: 425px) {
    padding: 24px 16px 0 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;

  .error-icon {
    height: 32px;
    padding-bottom: 8px;
  }

  @media only screen and (max-width: 425px) {
    padding: 16px;

    .error-icon {
      padding-bottom: 0;
    }
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.colors.gray400};

  @media only screen and (max-width: 425px) {
    font-size: 16px;
  }
`;

export const Message = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 0 16px;
  text-align: center;
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.gray400};

  @media only screen and (max-width: 425px) {
    font-size: 12px;
  }
`;
