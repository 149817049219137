import React from 'react';
import { ReactComponent as TargetSVG } from '../../assets/icons/target.svg';

const Target: React.FC<{ className?: string }> = ({
  className,
}: {
  className?: string;
}) => {
  return <TargetSVG className={className} />;
};

export { Target };
