import React from 'react';
import { ReactComponent as CheckImg } from '../../assets/images/check.svg';

const Check: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <CheckImg width="22px" height="17px" className={className} />;
};

export { Check };
