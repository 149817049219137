import styled from 'styled-components';

interface ScrollProp {
  scrollMarginX: boolean;
  scrollMarginY: boolean;
}

interface IDraftProp {
  isDraft: boolean;
}

export const Container = styled.div<IDraftProp>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  height: ${props => props.isDraft ? 'calc(100vh - 460px)' : 'calc(100vh - 400px)'};
  position: relative;

  @media only screen and (max-width: 425px) {
    height: ${props => props.isDraft ? 'calc(100vh - 365px)' : 'calc(100vh - 300px)'};
  }
`;

export const ContainerWrapper = styled.section <IDraftProp>`
  display: flex;
  flex-direction: column;
  height: ${props => props.isDraft ? 'calc(100% - 28px)' : '100%'};
  justify-content: space-between;

  @media only screen and (max-width: 425px) {
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Content = styled.div<ScrollProp>`
  max-height: calc(100vh - 356px);
  height: 100%;
  overflow: auto;
  margin-right: ${({ scrollMarginY }) => !scrollMarginY && '30px'};
  margin-bottom: ${({ scrollMarginX }) => !scrollMarginX && '30px'};

  ::-webkit-scrollbar {
    width: 0;
    height: 40px;
    transform: translate(-20px);
  }
  ::-webkit-scrollbar-track {
      background: transparent;
      margin: 39px -10px -10px;
  }
  ::-webkit-scrollbar-thumb {
      background: #a1b4c4;
      border-radius: 50px;
      border: 15px solid ${({ theme }) => theme.colors.white};
  }

  ::-webkit-scrollbar-corner {
    background: none;
  }

  @media only screen and (min-width: 426px) {
    ::-webkit-scrollbar {
      width: 70px;
      height: 70px;
      transform: translate(-20px);
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      margin: 59px -30px -30px;
    }
    ::-webkit-scrollbar-thumb {
      background: #a1b4c4;
      border-radius: 50px;
      border: 30px solid
        ${({ theme }) => theme.colors.white};
    }
  }


`;

export const SubContent = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 10px 10px;
  height: auto;

  @media only screen and (max-width: 425px) {
    padding: 16px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
    gap: 16px;
  }
`;
