import { Button } from 'components';
import { Footer } from 'components/Footer';
import { ICliente } from 'core/pesquisa/Pesquisa';
import { Container, ContainerButton, Content, HirerName, SubTitle, Title, Wrapper } from './styles';

interface Props {
  iniciarPesquisa: () => void;
  titulo: string;
  descricao: string;
  cliente: ICliente;
  logoContratante: string;
  isDraft: boolean;
}

export const StartSurveyBox = ({
  iniciarPesquisa,
  titulo,
  descricao,
  cliente,
  logoContratante,
  isDraft,
}: Props) => {
  const handleClick = () => {
    if (iniciarPesquisa) iniciarPesquisa();
  };

  return (
    <Container isDraft={isDraft}>
      <Content>
        <Wrapper>
          <img src={logoContratante} alt="logo-contratante" />
          <HirerName>{cliente.nome}</HirerName>
          <Title>{titulo}</Title>
          <SubTitle>{descricao}</SubTitle>
        </Wrapper>
        <ContainerButton>
          <Button customButton="default" title="Iniciar" onClick={handleClick} />
        </ContainerButton>
      </Content>
      <Footer />
    </Container>
  );
};
