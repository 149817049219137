/**
 * Adiciona a string "hora" em um horário para renderização na listagem de horas.
 * @param value Hora HH
 * @returns Hora formatada: (HH) Hora(s)
 */
export const formatHourToLabel = (value: string) => {
  if (!value) return '';
  return `${value} hora${value === '1' ? '' : 's'}`;
};

/**
 * Adiciona a string "min" em um horário de minuto para renderização na listagem de minutos.
 * @param value Minuto MM
 * @returns Minuto formatado: (MM) min
 */
export const formatMinuteToLabel = (value: string) => {
  if (!value) return '';
  return `${value} min`;
};

/**
 * Formata um horario do tipo h:m para dois digitos na parte de hora e na parte de minuto
 * @param horario Horario H:M | HH:M | H:MM
 * @returns Hora formatada HH:MM
 */
export const formatHorarioToTwoDigits = (horario: string) => {
  const convert = (t: string) => {
    return t.length === 1 ? `0${t}` : t;
  };

  const [h, m] = horario.split(':');
  const isFullHorario = typeof h !== 'undefined' && typeof m !== 'undefined';

  return isFullHorario ? `${convert(h)}:${convert(m)}` : convert(h);
};

/**
 * Recupera um horário do tipo HH:MM dado uma hora H e um minuto M
 * @param hour hora H
 * @param minute minuto M
 */
export const getFullHorario = (hour: string, minute: string) => {
  return formatHorarioToTwoDigits(`${hour}:${minute}`);
};

/**
 * Verifica se é uma hora válida [0-23]
 * @param hour Hora H
 */
export const isValidHour = (hour: string) => {
  if (!hour || !hour.length || hour.length > 2) return false;

  const regex = /^([0-9]|[1][0-9]|[2][0-3])$/;
  return regex.test(Number(hour).toString());
};

/**
 * Verifica se é um minuto válido [0-59]
 * @param hour Hora H
 */
export const isValidMinute = (minute: string) => {
  if (!minute || !minute.length || minute.length > 2) return false;

  const regex = /^([0-9]|[1-5][0-9])$/;
  return regex.test(Number(minute).toString());
};

/**
 * Verifica se é um horário completo válido, será válido caso siga o modelo HH:MM, onde H >= 0 && H < 24
 * e M >= 0 && M < 60
 * @param horario Horario HH:MM (esperado)
 */
export const isValidFullHorario = (horario: string) => {
  const [h, m] = horario.split(':');

  return isValidHour(h) && isValidMinute(m);
};

export const replaceBadInputs = (hourInput: string) => {
  // Remove caracteres indesejados
  hourInput = hourInput.replace(/[^\d:]/g, '');
    
  // Adiciona ':' automaticamente após dois dígitos
  if (hourInput.length === 2 && !hourInput.includes(':')) {
    hourInput += ':';
  }

  // Valida a entrada completa
  hourInput = hourInput.replace(/^(2[4-9]|[3-9]\d).*$/, ''); // Remove horas inválidas (24-29, 30-99)
  hourInput = hourInput.replace(/^(\d{2}):([6-9]\d).*$/, '$1:'); // Remove minutos inválidos (60-99)

  return hourInput;
};

export const handleCompleteHour = (hour: string) => {
  if (hour.length === 3) {
    return `${hour}00`;
  } 
  return hour;
};
