import { NewLogo } from 'components/Icons';
import { FooterText, UiFooter } from './styles';

export const Footer = () => {
  return (
    <UiFooter>
      <NewLogo logoStyle="tensai-logo" />
      <FooterText>
        Distribuído por © 2024 Tensai. Todos os direitos reservados.
      </FooterText>
    </UiFooter>
  );
};
