import { ThemeProviderApp } from 'hooks/useTheme';
import { CoreProvider } from 'providers/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'styles/Global';
import './index.css';
import Routes from './routes';

// function getRelativePath() {
//   return process.env.REACT_APP_RELATIVE_PATH;
// }

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CoreProvider>
        <ThemeProviderApp>
          <GlobalStyle />
          <Routes />
        </ThemeProviderApp>
      </CoreProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
